import React, { useState } from "react";
import { TextField, Box, Button, CircularProgress, InputAdornment } from "@mui/material";
import { UpdateProfileRequestDto } from "@doctomatic/sdk/build/dto/User";
import { toast } from "react-toastify";
import { useApi } from "@doctomatic/sdk/build/Api";
import { useTranslation } from "react-i18next";
import { processError } from "../../../../App/errorToast";
import { useNavigate } from "react-router-dom";
import InfoModal from "../../../Modals/InfoModal";
import { IoEye, IoEyeOff } from "react-icons/io5";

interface PasswordFormProps {
  onClose: () => void;
  onSave?: () => void;
}

const PasswordForm = ({
  onClose,
  onSave,
}: PasswordFormProps): React.ReactElement => {
  const navigate = useNavigate();
  const { useProfile, logout } = useApi();
  const { t } = useTranslation();
  const { update } = useProfile(false, true, processError(logout, navigate, t));

  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [repeatPassword, setRepeatPassword] = useState("");

  const [openModal, setOpenModal] = useState(false);
  const [textModal, setTextModal] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showRepeatPassword, setShowRepeatPassword] = useState(false);

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    setLoading(true);
    try {
      let dto = new UpdateProfileRequestDto();
      dto.password = password;
      await update(dto);
      setOpenModal(true);
      setTextModal(t("PasswordUpdated"));
    } catch (err: any) {
      toast.error(
        `${t("ChangePasswordError")}: ${err.response?.data?.message || err.message
        }`
      );
      setOpenModal(true);
      setTextModal(`${t("ChangePasswordError")}`);
    }
    setLoading(false);
  };

  const isValid =
    Boolean(password) &&
    Boolean(repeatPassword) &&
    password.length >= 6 &&
    repeatPassword === password;

  return (
    <>
      <InfoModal open={openModal} text={textModal} onClose={onSave} t={t} />
      <form noValidate onSubmit={handleSubmit}>
        <TextField
          helperText={t("ChangePasswordInvalid")}
          margin="normal"
          required
          fullWidth
          type={showPassword ? "text" : "password"}
          id="password"
          label={t("FieldPassword")}
          variant="outlined"
          onChange={(e) => setPassword(e.target.value)}
          autoFocus
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <button
                  type="button"
                  onClick={() => setShowPassword(!showPassword)}
                  style={{
                    background: "none",
                    border: "none",
                    padding: 0,
                    marginTop: 5,
                  }}
                >
                  {!showPassword ? (
                    <IoEyeOff size={20} color="gray" />
                  ) : (
                    <IoEye size={20} color="gray" />
                  )}
                </button>
              </InputAdornment>
            ),
            style: { borderRadius: 0 },
          }}
        />
        <TextField
          margin="normal"
          required
          fullWidth
          type={showRepeatPassword ? "text" : "password"}
          id="repeat-password"
          label={t("FieldRepeatPassword")}
          variant="outlined"
          onChange={(e) => setRepeatPassword(e.target.value)}
          value={repeatPassword}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <button
                  type="button"
                  onClick={() => setShowRepeatPassword(!showRepeatPassword)}
                  style={{
                    background: "none",
                    border: "none",
                    padding: 0,
                    marginTop: 5,
                  }}
                >
                  {!showRepeatPassword ? (
                    <IoEyeOff size={20} color="gray" />
                  ) : (
                    <IoEye size={20} color="gray" />
                  )}
                </button>
              </InputAdornment>
            ),
            style: { borderRadius: 0 },
          }}
        />
        <Box display="flex" justifyContent="flex-end" mt={3}>
          <Button
            type="submit"
            variant="contained"
            disabled={!isValid}
            style={{ color: "white" }}
          >
            {loading ? <CircularProgress size={20} /> : "Guardar"}
          </Button>
          <Box display="flex" justifyContent="center" ml={3}>
            <Button onClick={onClose} size="small">
              {t("ButtonCancel")}
            </Button>
          </Box>
        </Box>
      </form>
    </>
  );
};

export { PasswordForm };

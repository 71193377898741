import { GridColumns, GridValueGetterParams } from "@mui/x-data-grid";
import { TextWordsQuantityCell } from "@doctomatic/components-react/build/TextWordsQuantity/TextWordsQuantityCell";
import { useTranslation } from "react-i18next";
import LastReadDevicesDataRow from "./LastReadDevicesDataRow";
import { BreadcrumbNameMap } from "../../../../breadcrumbMap";
import { Link as NavLink } from "react-router-dom";
import { sortText } from "../../../../App/sortFunctions";
import {
  getDeviceName,
  getMeasurementsInRead,
  toLocaleString,
} from "../../utils";
import VisibilityIcon from "@mui/icons-material/Visibility";
import {
  IconButton,
  List,
  ListItemButton,
  Popover,
  Tooltip,
  Typography,
} from "@mui/material";
import Launch from "@mui/icons-material/Launch";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { renderCellMeasurement } from "../RenderCellMeasurement";

interface Props {
  showExternalId: boolean | undefined;
  showImageModal: (item: LastReadDevicesDataRow) => void;
  currentLanguage: string;
  onClick: (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    item: any
  ) => void;
  onClose: () => void;
  anchorEl: HTMLElement | null;
  popoverId: string;
}

export const LastReadDevicesGridColumns = ({
  showExternalId,
  showImageModal,
  currentLanguage,
  onClick,
  onClose,
  anchorEl,
  popoverId,
}: Props): GridColumns<LastReadDevicesDataRow> => {
  const { t } = useTranslation();

  const openPopover = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    item: any
  ) => {
    if (onClick) {
      onClick(event, item);
    }
  };

  const gridColumns: any[] = [];
  showExternalId &&
    gridColumns.push({
      field: "externalId",
      headerName: t("ExternalId"),
      width: 150,
      align: "left",
      valueGetter: (params: GridValueGetterParams) =>
        params.row.user.externalId ?? "--",
      sortable: false,
      renderCell: (params: { row: LastReadDevicesDataRow }) => {
        return <p>{params.row.user.externalId ?? "--"}</p>;
      },
    });
  gridColumns.push(
    ...[
      {
        field: "user.name",
        headerName: t("PatientName"),
        width: 250,
        align: "left",
        valueGetter: (params: GridValueGetterParams) => params.row.user.name,
        sortComparator: (v1: string, v2: string) => sortText(v1, v2),
        renderCell: (params: { row: LastReadDevicesDataRow }) => {
          const userPath = BreadcrumbNameMap.Patient.url.replace(
            ":patientId",
            params.row.user.id.toString()
          );
          return (
            <TextWordsQuantityCell
              item={params.row}
              words={[params.row.user.name]}
              page={userPath}
              routerLink={NavLink}
            />
          );
        },
      },
      {
        field: "device.name",
        headerName: t("Device"),
        width: 150,
        valueGetter: (params: GridValueGetterParams) =>
          params.row && params.row.device
            ? getDeviceName(currentLanguage, params.row.device, t)
            : "--",
        sortComparator: (v1: string, v2: string) => sortText(v1, v2),
        renderCell: (params: { row: LastReadDevicesDataRow }) => {
          return (
            <p>
              {params.row && params.row.device
                ? getDeviceName(currentLanguage, params.row.device, t)
                : "--"}
            </p>
          );
        },
      },
      {
        field: "id",
        headerName: t("Readings"),
        width: 120,
        sortable: false,
        renderCell: (params: { row: LastReadDevicesDataRow }) => {
          const readDevicePath = BreadcrumbNameMap.ReadDevice.url.replace(
            ":readDeviceId",
            params.row.id.toString()
          );
          return (
            <NavLink to={readDevicePath}>
              <Launch />
            </NavLink>
          );
        },
      },
      {
        field: "measurements",
        headerName: t("Measurements"),
        width: 150,
        valueGetter: (params: GridValueGetterParams) =>
          params.row
            ? getMeasurementsInRead(currentLanguage, params.row, t)
            : "--",
        sortComparator: (v1: string, v2: string) => sortText(v1, v2),
        renderCell: (params: { row: LastReadDevicesDataRow }) => {
          renderCellMeasurement({ params, currentLanguage, t });
        },
      },
      {
        field: "image",
        headerName: t("Image"),
        width: 100,
        sortable: false,
        renderCell: (params: { row: LastReadDevicesDataRow }) =>
          /**
           * If measurement is defined and it's not a Form
           */
          params.row.device && params.row.device.typeName !== "Form" ? (
            <IconButton
              onClick={() => showImageModal(params.row)}
              color="inherit"
            >
              <VisibilityIcon />
            </IconButton>
          ) : (
            "--"
          ),
      },
      {
        field: "created_at",
        headerName: t("CreatedAt"),
        width: 150,
        valueGetter: (params: GridValueGetterParams) =>
          params.row.measurement?.created_at,
        sortComparator: (v1: any, v2: any) =>
          new Date(v2!).getTime() - new Date(v1!).getTime(),
        renderCell: (params: { row: LastReadDevicesDataRow }) => {
          const content: JSX.Element = (
            <p>
              {params.row.created_at
                ? toLocaleString(new Date(params.row.created_at))
                : "--"}
            </p>
          );
          return content;
        },
      },
      {
        field: "go_to",
        headerName: t("GoTo"),
        filterable: false,
        sortable: false,
        renderCell: (params: { row: LastReadDevicesDataRow }) => {
          const alertPath = BreadcrumbNameMap.Alerts.url.replace(
            ":patientId",
            params.row.user.id.toString()
          );
          const alertConfigByValuePath =
            BreadcrumbNameMap.AlertConfigByValue.url.replace(
              ":patientId",
              params.row.user.id.toString()
            );
          const alertConfigByNoMeasurePath =
            BreadcrumbNameMap.AlertConfigByNoMeasure.url.replace(
              ":patientId",
              params.row.user.id.toString()
            );
          const trackingsPath = BreadcrumbNameMap.TrackingPatient.url.replace(
            ":patientId",
            params.row.user.id.toString()
          );
          const readDeviceActions = (
            <Popover
              id={`m${params.row.id}`}
              open={popoverId === `m${params.row.id}`}
              anchorEl={anchorEl}
              onClose={onClose}
            >
              <List>
                <ListItemButton component={NavLink} to={alertPath}>
                  {t("PatientListAlerts")}
                </ListItemButton>
                <ListItemButton component={NavLink} to={alertConfigByValuePath}>
                  {t("PatientListConfigAlertsByValue")}
                </ListItemButton>
                <ListItemButton
                  component={NavLink}
                  to={alertConfigByNoMeasurePath}
                >
                  {t("PatientListConfigAlertsByNoMeasure")}
                </ListItemButton>
                <ListItemButton component={NavLink} to={trackingsPath}>
                  {t("PatientListTreatments")}
                </ListItemButton>
              </List>
            </Popover>
          );
          return (
            <>
              <IconButton onClick={(event) => openPopover(event, params.row)}>
                <MoreVertIcon />
              </IconButton>
              {readDeviceActions}
            </>
          );
        },
      },
    ]
  );

  return gridColumns;
};

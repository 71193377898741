export var styles = {
    error: {
        bottom: 0,
        padding: 10,
        fontSize: "12px",
        color: "#FF526D",
    },
    containerModal: {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        maxWidth: "100%",
        height: "auto",
        bgcolor: "white",
        boxShadow: 24,
        outline: "none",
        padding: 2,
        borderRadius: 2,
        overflowY: "auto",
        maxHeight: "80vh",
    },
    container: {
        maxWidth: "750px",
        height: "auto",
        bgcolor: "white",
        boxShadow: 3,
        outline: "none",
        padding: 2,
        borderRadius: 3,
    },
    image: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    bottomButtons: {
        marginTop: 20,
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "stretch",
        height: "100%",
        padingHorizontal: "5px",
        overflow: "auto",
    },
    centeredItem: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "flex-start",
    },
    deleteOneButton: {
        padding: "8px",
        margin: "5px",
    },
    botBtn: {
        display: "flex",
        flex: 1,
        alignItems: "center",
        margin: "0 8px",
    },
    dwnBtn: {
        justifyContent: "flex-start",
        padding: "8px",
        marginRight: "8px",
        minWidth: 0
    },
    inputGrid: {
        display: "grid",
        gridTemplateColumns: "1fr 1fr",
        gridColumnGap: 16,
        overflowY: "scroll"
    },
    btnClose: {
        marginTop: 20,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    }
};

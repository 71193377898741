import { GetAlertConfigIndividualResponseDto } from "@doctomatic/sdk/build/dto/Alerts/config/individual/AlertConfigIndividual";
import { GetAlertConfigTemplateResponseDto } from "@doctomatic/sdk/build/dto/Alerts/config/template/AlertConfigTemplate";
import {
  AlertState,
  GetAlertUserAckDto,
} from "@doctomatic/sdk/build/dto/Alerts/IAlerts";

import { GetPatientResponseDto } from "@doctomatic/sdk/build/dto/Patient/Patient";
import { GetTreatmentResponseDto } from "@doctomatic/sdk/build/dto/Treatment/Treatments";
import { Role } from "@doctomatic/sdk/build/dto/User";

export default class AlertsDataRow {
  id = 0;
  patient: GetPatientResponseDto;
  color = "00ff00";
  label = "";
  comments = "";
  created_at: Date;
  ackBy: GetAlertUserAckDto[] = [];
  trackings: GetTreatmentResponseDto[] = [];
  actorId: number;
  actorRole: Role;
  checkbox: boolean;
  state: AlertState;
  individualConfig?: GetAlertConfigIndividualResponseDto;
  templateConfig?: GetAlertConfigTemplateResponseDto;
  measurement?: any;
  onClickCheckbox: (item: any) => void;
  viewModal?: (props: any) => React.ReactElement;

  constructor(
    id: number,
    patient: GetPatientResponseDto,
    color: string,
    label: string,
    comments: string,
    created_at: Date,
    ackBy: GetAlertUserAckDto[],
    trackings: GetTreatmentResponseDto[],
    actorId: number,
    actorRole: Role,
    state: AlertState,
    onClickCheckbox: (item: any) => void,
    viewModal?: (props: any) => React.ReactElement,
    individualConfig?: GetAlertConfigIndividualResponseDto,
    templateConfig?: GetAlertConfigTemplateResponseDto
  ) {
    this.id = id;
    this.patient = patient;
    this.color = color;
    this.label = label;
    this.comments = comments;
    this.created_at = created_at;
    this.ackBy = ackBy;
    this.viewModal = viewModal;
    this.actorId = actorId;
    this.trackings = trackings;
    if (ackBy.find((x: GetAlertUserAckDto) => x.user.id === actorId)) {
      this.checkbox = true;
    } else {
      this.checkbox = false;
    }
    this.onClickCheckbox = onClickCheckbox;
    this.actorRole = actorRole;
    this.state = state;
    this.individualConfig = individualConfig;
    this.templateConfig = templateConfig;
  }
}

import { GridColumns, GridValueGetterParams } from "@mui/x-data-grid";
import AlertConfigByValueDataRow from "./AlertConfigByValueDataRow";
import { AlertBox } from "@doctomatic/components-react/build/Alerts/AlertBox";
import { TextWordsQuantityCell } from "@doctomatic/components-react/build/TextWordsQuantity/TextWordsQuantityCell";
import { useTranslation } from "react-i18next";
import { IProvideApi, useApi } from "@doctomatic/sdk/build/Api";
import { IUseAlertConfigsByValue } from "@doctomatic/sdk/build/modules/AlertConfigIndividual/AlertConfigsByValue";
import { toast } from "react-toastify";
import {
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Popover,
  Switch,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import StopCircleIcon from "@mui/icons-material/StopCircle";
import PlayCircleFilledIcon from "@mui/icons-material/PlayCircleFilled";
import { Link } from "react-router-dom";
import { BreadcrumbNameMap } from "../../../../breadcrumbMap";
import { ResponseApiList } from "@doctomatic/sdk/build/global";
import { KeyedMutator } from "swr";
import { sortText } from "../../../../App/sortFunctions";
import {
  ItemAlertConfigByValueIndividualDto,
  UpdateAlertConfigByValueIndividualRequestDto,
} from "@doctomatic/sdk/build/dto/Alerts/config/individual/AlertConfigByValueIndividual";
import { getDeviceName, getSignName } from "../../utils";

interface PopoverConfig {
  onClick: (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    item: any
  ) => void;
  onEdit: (item: any) => void;
  onActivate: (alertConfig: ItemAlertConfigByValueIndividualDto) => void;
  onClose: () => void;
  anchorEl: HTMLElement | null;
  popoverId: string;
}

export interface IAlertConfigByValueGridColumnsProps {
  popoverConf?: PopoverConfig;
  mutate: KeyedMutator<ResponseApiList<ItemAlertConfigByValueIndividualDto>>;
  currentLanguage: string;
  messengerChannel: string;
}

export const AlertConfigByValueGridColumns = ({
  popoverConf,
  mutate,
  currentLanguage,
  messengerChannel,
}: IAlertConfigByValueGridColumnsProps): GridColumns<AlertConfigByValueDataRow> => {
  const { t } = useTranslation();
  const { useAlertConfigsByValue }: IProvideApi = useApi();
  const { patch }: IUseAlertConfigsByValue = useAlertConfigsByValue();

  const isIndividual = (item: AlertConfigByValueDataRow) => {
    return (
      !item.customAlertConfigTemplate ||
      (item.customAlertConfigTemplate && !item.fromTemplate)
    );
  };

  const toggleNotification = async (
    alertConfig: AlertConfigByValueDataRow,
    value: boolean,
    field: string
  ) => {
    try {
      const notification = JSON.parse(alertConfig.notification);
      const dto: UpdateAlertConfigByValueIndividualRequestDto =
        new UpdateAlertConfigByValueIndividualRequestDto();
      Object.assign(dto, alertConfig);
      dto.notification = { ...notification, [field]: value };

      await patch(alertConfig.id, dto);
      await mutate();
    } catch (err: any) {
      toast.error(`${t("ErrorNofifyPushNotification")}: ${err.message}`);
    }
  };

  const openPopover = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    item: any
  ) => {
    if (popoverConf?.onClick) {
      popoverConf?.onClick(event, item);
    }
  };

  const columns: GridColumns<AlertConfigByValueDataRow> = [];

  columns.push(
    {
      field: "device",
      headerName: t("Device"),
      width: 200,
      align: "left",
      valueGetter: (params: GridValueGetterParams) =>
        params.row.sign?.device.name,
      sortComparator: (v1: string, v2: string) => sortText(v1, v2),
      renderCell: (params) => {
        return (
          <p>{getDeviceName(currentLanguage, params.row.sign?.device, t)}</p>
        );
      },
    },
    {
      field: "sign",
      headerName: t("Sign"),
      width: 100,
      valueGetter: (params: GridValueGetterParams) => t(params.row?.sign),
      sortComparator: (v1: string, v2: string) => sortText(v1, v2),
      renderCell: (params) => {
        return <p>{getSignName(currentLanguage, params.row.sign, t)}</p>;
      },
    },
    {
      field: "color",
      headerName: t("Level"),
      width: 100,
      valueGetter: (params: GridValueGetterParams) => params.row.color,
      sortComparator: (v1: string, v2: string) => sortText(v1, v2),
      renderCell: (params) => (
        <AlertBox id={params.row.id} color={params.row.color}></AlertBox>
      ),
    },
    {
      field: "description",
      headerName: t("Description"),
      width: 200,
      valueGetter: (params: GridValueGetterParams) => params.row.label ?? "--",
      sortComparator: (v1: string, v2: string) => sortText(v1, v2),
      renderCell: (params) => {
        return (
          <p>
            {params.row.sign.device.typeName === "Form" &&
            (params.row.label === "Yes" || params.row.label === "No")
              ? t(params.row.label)
              : params.row.label}
          </p>
        );
      },
    },
    {
      field: "alert_type",
      headerName: t("AlertType"),
      width: 220,
      valueGetter: (params: GridValueGetterParams) =>
        params.row.alert_type ? t(params.row.alert_type) : "--",
      sortComparator: (v1: string, v2: string) => sortText(v1, v2),
      renderCell: (params) => {
        return <p>{params.row.alert_type ? t(params.row.alert_type) : "--"}</p>;
      },
    },
    {
      field: "min",
      headerName: t("FieldMin"),
      width: 100,
      valueGetter: (params: GridValueGetterParams) => params.row.min ?? "--",
      sortComparator: (v1: any, v2: any) =>
        v1!.toString().localeCompare(v2!.toString()),
      renderCell: (params) => {
        return (
          <p>
            {params.row.sign.device.typeName === "Form" && params.row.min
              ? params.row.valueText
                ? t(params.row.valueText)
                : "--"
              : params.row.min ?? "--"}
          </p>
        );
      },
    },
    {
      field: "max",
      headerName: t("FieldMax"),
      width: 100,
      valueGetter: (params: GridValueGetterParams) => params.row.max ?? "--",
      sortComparator: (v1: any, v2: any) =>
        v1!.toString().localeCompare(v2!.toString()),
      renderCell: (params) => {
        return (
          <p>
            {params.row.sign.device.typeName === "Form" && params.row.max
              ? params.row.valueText
                ? t(params.row.valueText)
                : "--"
              : params.row.max ?? "--"}
          </p>
        );
      },
    },
    {
      field: "range_value",
      headerName: t("RangeValue"),
      width: 100,
      valueGetter: (params: GridValueGetterParams) =>
        params.row.range_value ?? "--",
      sortComparator: (v1: any, v2: any) =>
        v1!.toString().localeCompare(v2!.toString()),
      renderCell: (params) => {
        return <p>{params.row.range_value ?? "--"}</p>;
      },
    },
    {
      field: "range_time",
      headerName: t("RangeTime"),
      width: 100,
      valueGetter: (params: GridValueGetterParams) =>
        params.row.range_time ?? "--",
      sortComparator: (v1: any, v2: any) =>
        v1!.toString().localeCompare(v2!.toString()),
      renderCell: (params) => {
        return <p>{params.row.range_time ?? "--"}</p>;
      },
    },
    {
      field: "subscribers",
      headerName: t("Subscribers"),
      width: 250,
      valueGetter: (params: GridValueGetterParams) => {
        const words: string[] = params.row.subscribers ?? [];
        if (words.length > 0) {
          return words.join(", ");
        } else return "--";
      },
      sortable: false,
      renderCell: (params) => {
        const subscriberNames: string[] =
          params.row.subscribers && params.row.subscribers.length > 0
            ? params.row.subscribers
                .filter((item) => item.user)
                .map((item) => (item.user ? item.user.name : ""))
            : ["--"];
        subscriberNames.length === 0 && subscriberNames.push("--");
        return (
          <TextWordsQuantityCell
            routerLink={Link}
            item={params.row}
            words={subscriberNames}
            page={BreadcrumbNameMap.AlertConfigSubscribers.url.replace(
              ":alertConfigId",
              params.row.id.toString()
            )}
          ></TextWordsQuantityCell>
        );
      },
    },
    {
      field: "notification",
      headerName: t("FieldPushNotifyPatient"),
      width: 200,
      valueGetter: (params: GridValueGetterParams) =>
        params.row.notification && JSON.parse(params.row.notification)?.pushNotification,
      renderCell: (params) =>
        params.row.notification && isIndividual(params.row) ? (
          <Switch
            disabled={params.row.active ? false : true}
            checked={JSON.parse(params.row.notification)?.pushNotification}
            onChange={(event: React.ChangeEvent<HTMLInputElement>): any => {
              toggleNotification(
                params.row,
                event.target.checked,
                "pushNotification"
              );
            }}
          />
        ) : (
          <></>
        ),
    }
  );

  if (JSON.parse(messengerChannel).sms) {
    columns.push({
      field: "sms",
      headerName: t("FieldSMSNotifyPatient"),
      width: 200,
      valueGetter: (params: GridValueGetterParams) =>
        params.row.notification && JSON.parse(params.row.notification).sms,
      renderCell: (params) =>
        params.row.notification && isIndividual(params.row) ? (
          <Switch
            disabled={params.row.active ? false : true}
            checked={JSON.parse(params.row.notification).sms}
            onChange={(event: React.ChangeEvent<HTMLInputElement>): any => {
              toggleNotification(params.row, event.target.checked, "sms");
            }}
          />
        ) : (
          <></>
        ),
    });
  }

  if (JSON.parse(messengerChannel).email) {
    columns.push({
      field: "email",
      headerName: t("FieldEmailNotifyPatient"),
      width: 200,
      valueGetter: (params: GridValueGetterParams) =>
        params.row.notification && JSON.parse(params.row.notification).email,
      renderCell: (params) =>
        params.row.notification && isIndividual(params.row) ? (
          <Switch
            disabled={params.row.active ? false : true}
            checked={JSON.parse(params.row.notification).email}
            onChange={(event: React.ChangeEvent<HTMLInputElement>): any => {
              toggleNotification(params.row, event.target.checked, "email");
            }}
          />
        ) : (
          <></>
        ),
    });
  }

  columns.push(
    {
      field: "active",
      headerName: t("ActiveColumn"),
      width: 200,
      valueGetter: (params: GridValueGetterParams) => params.row.active,
      renderCell: (params) => {
        return <p>{params.row.active ? t("Active") : t("Deactive")}</p>;
      },
    },
    {
      field: "viewModal",
      headerName: t("Actions"),
      width: 200,
      filterable: false,
      sortable: false,
      renderCell: (params) => (
        <>
          <IconButton onClick={(event) => openPopover(event, params.row)}>
            <MoreVertIcon />
          </IconButton>
          <Popover
            id={`m${params.row.id}`}
            open={popoverConf?.popoverId === `m${params.row.id}`}
            anchorEl={popoverConf?.anchorEl}
            onClose={popoverConf?.onClose}
          >
            <List>
              <ListItem>
                <ListItemButton
                  component={Link}
                  to={BreadcrumbNameMap.AlertConfigSubscribers.url.replace(
                    ":alertConfigId",
                    params.row.id.toString()
                  )}
                >
                  <ListItemText primary={t("Subscribers")} />
                </ListItemButton>
              </ListItem>
              <ListItem
                button
                onClick={() => {
                  popoverConf?.onClose();
                  popoverConf?.onEdit(params.row);
                }}
              >
                <ListItemIcon>
                  <EditOutlinedIcon />
                </ListItemIcon>
                <ListItemText primary={t("Edit")} />
              </ListItem>
              <ListItem
                button
                onClick={() => {
                  popoverConf?.onClose();
                  popoverConf?.onActivate(params.row);
                }}
              >
                <ListItemIcon>
                  {" "}
                  {params.row.active ? (
                    <StopCircleIcon />
                  ) : (
                    <PlayCircleFilledIcon />
                  )}{" "}
                </ListItemIcon>
                <ListItemText
                  primary={params.row.active ? t("Deactivate") : t("Activate")}
                />
              </ListItem>
            </List>
          </Popover>
        </>
      ),
    }
  );

  return columns;
};

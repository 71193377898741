import React from 'react';
import Tooltip from '@mui/material/Tooltip';
import { getMeasurementsInRead } from '../utils';

export interface Props {
    params: any;
    currentLanguage: string;
    t: any;
}

export const renderCellMeasurement = (
    { params,
        currentLanguage,
        t,}: Props
): React.ReactElement => {
    const { row } = params
    const { device, measurementsInRead } = row || {};
    const result =
        row && measurementsInRead
            ? getMeasurementsInRead(
                currentLanguage,
                row,
                t
            )
            : '--';

    const lines = result !== '--' ? result.trim().split('\n') : [];
    const tooltipContent = result;

    const commonTooltipProps = {
        disableInteractive: true,
        componentsProps: {
            tooltip: {
                sx: {
                    fontSize: '14px',
                    maxWidth: 800,
                    whiteSpace: 'pre-line',
                },
            },
        },
    };

    if (device?.type_device?.toLowerCase().includes('form')) {
        return (
            <Tooltip title={tooltipContent} {...commonTooltipProps} placement="top-start">
                <span>{tooltipContent}</span>
            </Tooltip>
        );
    }

    return (
        <div>
            {lines.length > 0 ? (
                lines.map((line, index) => {
                    const [label, value] = line.split(':');
                    return (
                        <Tooltip
                            key={index}
                            title={tooltipContent}
                            {...commonTooltipProps}
                            placement="top-start"
                        >
                            <span
                                style={{
                                    whiteSpace: 'normal',
                                    wordWrap: 'break-word',
                                    textOverflow: 'ellipsis',
                                }}
                            >
                                {label}:<strong>{value}</strong>
                                {index < lines.length - 1 && ' '}
                            </span>
                        </Tooltip>
                    );
                })
            ) : (
                <span>{result}</span>
            )}
        </div>
    );
}

import React from 'react';
import { Modal, Box, Typography, Button } from '@mui/material';

interface Props {
    open: boolean;
    onClose?: () => void ;
    text: string | undefined;
    t: any;
  }

const CustomModal = ({ open, text, onClose, t }:Props) : React.ReactElement => {
    return (
        <Modal
            open={open}
            onClose={onClose}
        >
            <Box sx={styles.modalContainer}>
                <Typography id="modal-text" variant="h6" component="h2">
                    {text}
                </Typography>
                <Button variant="contained" onClick={onClose} sx={{ mt: 3 }}>
                {t("ButtonClose")}
                </Button>
            </Box>
        </Modal>
    );
};


const styles = {
    modalContainer: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: 4,
        borderRadius: 1,
        textAlign: 'center',
    },
};

export default CustomModal;

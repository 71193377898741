import React, { useState } from "react";
import { useApi } from "@doctomatic/sdk/build/Api";
import { useTranslation } from "react-i18next";
import {
  GridCallbackDetails,
  GridColumns,
  GridSelectionModel,
} from "@mui/x-data-grid";
import TreatmentsDataRow from "./TreatmentsDataRow";
import { toast } from "react-toastify";
import { Theme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import convertAndExportCsv from "./export-csv";
import { toLocaleString } from "../utils";
import { BreadcrumbProps } from "@doctomatic/components-react/build/BreadcrumbDocto/BreadcrumbDocto";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  Box,
  Checkbox,
  FormControlLabel,
  Typography,
  Link,
  useMediaQuery,
} from "@mui/material";
import { IGetTreatmentResponseDto } from "@doctomatic/sdk/build/dto/Treatment/ITreatments";
import { TreatmentsList } from "./TreatmentsList";
import { GetTreatmentResponseDto } from "@doctomatic/sdk/build/dto/Treatment/Treatments";
import { Page } from "../Page";
import { formatValues, getValues6Mwt } from "./utilsTreatments";

const useStyles = makeStyles((theme: Theme) => ({
  total: {
    marginRight: 16,
    padding: "4px 0",
    lineHeight: 1.75,
    fontSize: 16,
  },
  showEndedLabel: {
    lineHeight: 1.75,
    fontSize: 16,
  },
}));

export interface TreatmentsProps {
  breadcrumbs: BreadcrumbProps;
  treatmentsDataRows: TreatmentsDataRow[];
  treatmentsGridColumns: GridColumns<TreatmentsDataRow>;
  treatments: GetTreatmentResponseDto[] | undefined;
  buttons?: React.ReactNode | undefined;
  treatmentsPaginationInfo?: any;
  isLoading?: boolean;
  onPageChange?: (newPageNumber: number) => void;
  onPageSizeChange?: (newPageSize: number) => void;
  showEnded?: boolean;
  setShowEnded: (showEnded: boolean) => void;
  totalTreatments?: number;
}

export const Treatments = ({
  breadcrumbs,
  treatmentsDataRows,
  treatmentsGridColumns,
  treatments,
  buttons,
  treatmentsPaginationInfo,
  isLoading,
  onPageChange,
  onPageSizeChange,
  showEnded = false,
  setShowEnded,
  totalTreatments,
}: TreatmentsProps): React.ReactElement => {
  const { role } = useApi();
  const classes = useStyles();
  const { useMeasurements } = useApi();
  const { t, i18n } = useTranslation();
  const { findAllByTreatmentExtended } = useMeasurements();
  const [openDialogExport, setOperDialogExport] = useState(false);
  const [selectionModel, setSelectionModel] =
    React.useState<GridSelectionModel>([]);
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("sm")
  );

  const onExportCsv = async (
    selectionModel: GridSelectionModel,
    rows: TreatmentsDataRow[],
    individual?: boolean
  ) => {
    try {
      let headers = {
        trackingName: formatValues(t("trackingName")),
        patientId: formatValues(t("PatientId")),
        patientName: formatValues(t("PatientName")),
        id: "Id",
        sign: formatValues(t("Sign")),
        value: formatValues(t("Value")),
        unit: formatValues(t("Unit")),
        image: formatValues(t("Image")),
        deviceId: formatValues(t("DeviceId")),
        device: formatValues(t("Device")),
        createdAt: formatValues(t("CreatedAt")),
      };

      let fileName = `trackings-patients_${new Date().toDateString()}`;

      // This array will contain all the measurements of all the selected items. It will be used to export all the measurements of all the selected items when the user clicks on the button "Export all" or "Export one without modal"
      let allFormattedMeasurements: any[] = [];

      // Iterate over the selected rows
      for (const selectedElementId in selectionModel) {
        // Get item selected
        let item = rows.find(
          (e) => e.id === +selectionModel[selectedElementId]
        );

        // Get all the measurements of the selected item
        const response = await findAllByTreatmentExtended(item!.id);

        // This array will contain all the measurements of the selected item. It will be used to export all the measurements of the selected item when the user clicks on the button "Export one with modal"
        let formattedMeasurements: any[] = [];
        if (response && response.data) {
          response.data.forEach((m: any) => {
            let measurmentToPush = {};
            if (m.steps) {
              formattedMeasurements.push(...getValues6Mwt(item, m, t));
              allFormattedMeasurements.push(...getValues6Mwt(item, m, t));
            } else {
              measurmentToPush = {
                trackingName: item?.name,
                patientId: item?.patient.id,
                patientName: item?.patient.name,
                id: m.id,
                sign: formatValues(t(m.sign.name)),
                value: formatValues(m.value.toString()),
                unit: formatValues(m.sign.unit),
                image: m.read_device.key,
                deviceId: formatValues(t(m.sign.device.id.toString())),
                device: formatValues(t(m.sign.device.name)),
                createdAt: formatValues(toLocaleString(new Date(m.createdAt))),
              };
              formattedMeasurements.push(measurmentToPush);
              allFormattedMeasurements.push(measurmentToPush);
            }
          });
        }
        // Export the measurements of the selected item when the user clicks on the button "Export one with modal"
        if (individual && formattedMeasurements.length > 0) {
          convertAndExportCsv(headers, formattedMeasurements, fileName);
        }
      }

      // Export the measurements of all the selected items when the user clicks on the button "Export all" or "Export one without modal"
      if (!individual) {
        convertAndExportCsv(headers, allFormattedMeasurements, fileName);
      }
    } catch (err: any) {
      toast.error(
        `${t("ErrorExportTreatmentMeasurements")}: ${
          err.response?.data?.message || err.message
        }`
      );
    }
  };

  const treatmentsList = (
    <TreatmentsList
      language={i18n.language}
      columns={treatmentsGridColumns as GridColumns}
      rows={treatmentsDataRows}
      onSelectionModelChange={(
        selectionModel: GridSelectionModel,
        details: GridCallbackDetails<any>
      ) => {}}
      onSelectionModelChangeFunc={function (
        selectionModel: GridSelectionModel,
        details: GridCallbackDetails<any>
      ): void {
        setSelectionModel(selectionModel);
      }}
      checkBox={role !== "user"}
      treatmentsPaginationInfo={treatmentsPaginationInfo}
      isLoading={isLoading}
      onPageChange={onPageChange}
      onPageSizeChange={onPageSizeChange}
    />
  );

  const dialogExportCsvGroupedOrIndividual = (
    <Dialog
      open={openDialogExport}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{t("exportCsvTitle")}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {t("exportCsvContentText")}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            setOperDialogExport(false);
            onExportCsv(selectionModel, treatmentsDataRows, true);
          }}
        >
          {t("ExportCsvIndividual")}
        </Button>
        <Button
          onClick={() => {
            setOperDialogExport(false);
            onExportCsv(selectionModel, treatmentsDataRows);
          }}
          autoFocus
        >
          {t("ExportCsvGrouped")}
        </Button>
        <Button
          onClick={() => {
            setOperDialogExport(false);
          }}
          autoFocus
        >
          {t("Cancel")}
        </Button>
      </DialogActions>
    </Dialog>
  );

  const footer = (
    <Box
      my={2}
      px={2}
      display="flex"
      justifyContent="flex-end"
      alignItems={"center"}
    >
      <Typography
        style={isMobile ? { fontSize: "14px" } : {}}
        className={classes.total}
        marginRight="8px"
      >
        {t("TreatmentListTotal")}: {totalTreatments}
      </Typography>
      <Typography
        style={isMobile ? { fontSize: "14px" } : {}}
        className={classes.total}
        marginRight="8px"
      >
        {t("TreatmentListEnded")}:{" "}
        {treatments &&
          treatments.filter(
            (value: IGetTreatmentResponseDto) =>
              value.closed_at !== null &&
              new Date(value.closed_at as Date) < new Date()
          ).length}
      </Typography>
      <FormControlLabel
        control={
          <Checkbox
            style={{ paddingLeft: "8px", marginRight: "0" }}
            checked={showEnded}
            onChange={() => setShowEnded(!showEnded)}
          />
        }
        label={
          <Typography
            style={isMobile ? { fontSize: "14px" } : {}}
            className={classes.showEndedLabel}
          >
            {t("TreatmentListShowEnded")}
          </Typography>
        }
        labelPlacement="start"
      />
    </Box>
  );

  return (
    <Page
      title=""
      breadCrumbProps={breadcrumbs}
      footer={footer}
      buttons={
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          {role !== "user" && (
            <Link
              underline="always"
              onClick={() => {
                if (selectionModel.length === 1)
                  onExportCsv(selectionModel, treatmentsDataRows);
                if (selectionModel.length > 1) setOperDialogExport(true);
              }}
            >
              {t("exportTrackings")}({selectionModel.length.toString()})
            </Link>
          )}

          {buttons}
        </div>
      }
    >
      {treatmentsList}
      {dialogExportCsvGroupedOrIndividual}
    </Page>
  );
};

var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import React, { useEffect } from "react";
import { SignType } from "../../models";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { IconButton, Tooltip } from "@mui/material";
export var NumericValuesGridColumns = function (_a) {
    var signs = _a.signs, t = _a.t, showImageModal = _a.showImageModal, additionalData = _a.additionalData, currentLanguage = _a.currentLanguage, measurements = _a.measurements;
    var _b = React.useState(false), isTypeQuestionDevice = _b[0], setIsTypeQuestionDevice = _b[1];
    var _c = React.useState([0]), questionIndex = _c[0], setQuestionIndex = _c[1];
    var _d = React.useState(undefined), questions = _d[0], setQuestions = _d[1];
    function getQuestionName(currentLanguage, question, t, name) {
        var questionName = question
            ? question
                .map(function (data) {
                if (data.lang === currentLanguage) {
                    return data.text;
                }
                return null;
            })
                .filter(function (text) { return text !== null; })[0] || t(name)
            : t(name);
        return questionName;
    }
    var getName = function (index, name, unit) {
        var questionName = name;
        if (questionName.includes("question")) {
            if (questions) {
                questionName = getQuestionName(currentLanguage, questions[questionIndex[index]].text, t, name);
            }
            if (unit) {
                var questionUnit = getUnit(questionIndex[index], unit);
                questionName += " (".concat(questionUnit, ")");
            }
        }
        return questionName;
    };
    var getUnit = function (index, unit) {
        if (questions) {
            return getQuestionName(currentLanguage, questions[index].unit, t, unit);
        }
        return unit;
    };
    useEffect(function () {
        if (additionalData && additionalData !== "") {
            var data = JSON.parse(additionalData);
            setQuestions(data.questions);
            data.questions.forEach(function (question) {
                if (question.typeQuestion === "device") {
                    setIsTypeQuestionDevice(true);
                }
            });
        }
    }, [additionalData]);
    useEffect(function () {
        if (questions && isTypeQuestionDevice && measurements) {
            var index = measurements.map(function (measurement) {
                return questions.findIndex(function (question) {
                    return question.signId.includes(measurement.signId);
                });
            });
            setQuestionIndex(index.sort(function (a, b) { return a - b; }));
        }
    }, [isTypeQuestionDevice, questions, measurements]);
    var values = signs.map(function (sign, index) {
        var _a, _b, _c;
        return ({
            field: getName(index, t((_a = sign.name) !== null && _a !== void 0 ? _a : "")),
            headerName: getName(index, t((_b = sign.name) !== null && _b !== void 0 ? _b : ""), t((_c = sign.unit) !== null && _c !== void 0 ? _c : "")),
            width: 100,
            sortable: true,
            filterable: true,
            sortComparator: function (v1, v2) {
                return v1.toString().localeCompare(v2.toString());
            },
            renderCell: function (params) {
                var _a, _b;
                var measurement = params.row.values.find(function (value) { return value.signId === sign.id; });
                /**
                 * If measurement exists, check if it's an image from a form.
                 * If not, display their text value if available or their numeric value.
                 * If measurement is null or any of those values are null or undefined,
                 * display an empty string
                 */
                var value = "";
                if (measurement) {
                    if (measurement.sign.typeSign === SignType.image) {
                        return (React.createElement(IconButton, { onClick: function () { return showImageModal(measurement); }, color: "inherit" },
                            React.createElement(VisibilityIcon, null)));
                    }
                    else {
                        value = measurement.valueText
                            ? measurement.sign.typeSign === SignType.time
                                ? measurement.valueText
                                : t(measurement.valueText)
                            : (_b = (_a = measurement.value) === null || _a === void 0 ? void 0 : _a.toString()) !== null && _b !== void 0 ? _b : "";
                    }
                }
                return (React.createElement(Tooltip, { title: value },
                    React.createElement("p", null, value)));
            },
        });
    });
    return __spreadArray([
        {
            field: "viewImage",
            headerName: t("ViewImage"),
            width: 100,
            renderCell: function (params) {
                var measurement = params.row.values
                    ? params.row.values[0]
                    : undefined;
                return measurement &&
                    measurement.deviceTypeName !== "Form" &&
                    measurement.deviceTypeName !== "External" ? (React.createElement(IconButton, { onClick: function () { return showImageModal(measurement); }, color: "inherit" },
                    React.createElement(VisibilityIcon, null))) : ("--");
            },
        },
        {
            field: t("Date"),
            headerName: t("Date"),
            width: 130,
            sortable: true,
            filterable: true,
            valueGetter: function (params) { return params.row.date; },
            sortComparator: function (v1, v2) {
                return new Date(v2).getTime() - new Date(v1).getTime();
            },
            renderCell: function (params) { return React.createElement("p", null, params.row.date.toLocaleString()); },
        }
    ], values, true);
};

import React, { useEffect, useMemo, useState } from "react";
import { FlexLayoutGrid } from "@doctomatic/components-react/build/DataGrid/DataGrid";
import { IProvideApi, useApi } from "@doctomatic/sdk/build/Api";
import { GridColumns } from "@mui/x-data-grid";
import { useTranslation } from "react-i18next";
import { Page } from "../../Page";
import { LastReadDevicesByPatientGridColumns } from "./LastReadDevicesByPatientGridColumns";
import { UpdateMeasurementRequestDto } from "@doctomatic/sdk/build/dto/Measurements/Measurements";
import { processError } from "../../../../App/errorToast";
import { ImageItemInterface } from "../../../Images/utils";
import { PermissionDto } from "@doctomatic/sdk/build/dto/GroupPermission/GroupPermission";
import { IUseMeasurements } from "@doctomatic/sdk/build/modules/Measurements";
import { ImageEditModal } from "@doctomatic/components-react/build/ImagesViews/ImageEditModal";
import {
  BasicMeasurement,
  Device,
} from "@doctomatic/components-react/build/Graphs/models";
import { useLoading } from "../../../Loading/Loading";
import { toast } from "react-toastify";
import { ImageViewModal } from "@doctomatic/components-react/build/ImagesViews/ImageViewModal";
import LastReadDevicesByPatientDataRow from "./LastReadDevicesByPatientDataRow";
import { IUseLastReadDevices } from "@doctomatic/sdk/build/modules/ReadDevice/LastReadDevice";
import { ItemReadDeviceListResponseDto } from "@doctomatic/sdk/build/dto/ReadDevice/ReadDevice";
import { useNavigate } from "react-router-dom";

export interface ILastReadDevicesListProps {
  actorId: number;
  showExternalId: boolean | undefined;
}

export const LastReadDevicesByPatientList = ({
  actorId,
}: ILastReadDevicesListProps): React.ReactElement => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const {
    useLastReadDevices,
    logout,
    timezone,
    useImages,
    useMeasurements,
    useDevices,
  }: IProvideApi = useApi();
  const { getDevice } = useDevices(false, processError(logout, navigate, t));
  const {
    updateMany,
    del,
    delByReadDevice,
    findAllByReadDevice,
  }: IUseMeasurements = useMeasurements(
    undefined,
    undefined,
    undefined,
    undefined,
    false,
    processError(logout, navigate, t)
  );
  const { getUrl } = useImages(false, processError(logout, navigate, t));
  const { setIsLoading, isLoading } = useLoading();
  const [lastReadDevices, setLastReadDevices] = useState<
    ItemReadDeviceListResponseDto[]
  >([]);
  const [imageItem, setImageItem] = useState<ImageItemInterface | null>(null);
  const [error, setError] = useState(false);

  const [permissions, setPermissions] = useState<PermissionDto[]>([]);
  const [editMeasurementsPermissions, setEditMeasurementsPermissions] =
    useState<boolean>(false);
  const [deleteMeasurementsPermissions, setDeleteMeasurementsPermissions] =
    useState<boolean>(false);
  const [imageMeasurements, setImageMeasurements] = useState<
    BasicMeasurement[]
  >([]);
  const [device, setDevice] = useState<Device>();
  const [measurementToDelete, setMeasurementToDelete] = useState<{
    id: number;
    name: string;
  } | null>(null);
  const [readDeviceToDelete, setReadDeviceToDelete] = useState<boolean>(false);
  const [currentLanguage, setCurrentLanguage] = useState<string>(i18n.language);

  // Declaration of hooks to handle pagination
  const [pageSizeByLastReadDevices, setPageSizeByLastReadDevices] =
    useState<number>(10);
  const [pageByLastReadDevices, setPageByLastReadDevices] = useState<number>(1);

  // Store the pagination value
  // Update pagination when page or pageSize changes
  const paginationValue = useMemo(() => {
    return {
      page: pageByLastReadDevices,
      limit: pageSizeByLastReadDevices,
    };
  }, [pageByLastReadDevices, pageSizeByLastReadDevices]);

  const { response: lastReadDeviceResponse, mutate }: IUseLastReadDevices =
    useLastReadDevices(
      actorId,
      paginationValue,
      undefined,
      undefined,
      undefined,
      true,
      processError(logout, navigate, t)
    );

  const lastReadDevicesByUser = lastReadDeviceResponse?.data;
  const lastReadDevicesByUserPaginationInfo = lastReadDeviceResponse?.meta;

  useEffect(() => {
    if (lastReadDevicesByUser) setLastReadDevices(lastReadDevicesByUser);
  }, [lastReadDevicesByUser, lastReadDevicesByUserPaginationInfo]);

  useEffect(() => {
    let cache = localStorage.getItem("permissions");
    if (cache) setPermissions(JSON.parse(cache));
  }, []);

  useEffect(() => {
    if (permissions && permissions.length > 0) {
      if (
        permissions.find((p) => {
          return p.key === "update.measurement";
        })
      ) {
        setEditMeasurementsPermissions(true);
      } else {
        setEditMeasurementsPermissions(false);
      }
      if (
        permissions.find((p) => {
          return p.key === "delete.measurement";
        })
      ) {
        setDeleteMeasurementsPermissions(true);
      } else {
        setDeleteMeasurementsPermissions(false);
      }
    }
  }, [permissions]);

  useEffect(() => {
    setCurrentLanguage(i18n.language);
  }, [i18n.language]);

  const handleError = () => {
    setError(true);
  };

  const openImageModal = async (item: any) => {
    let imageUrlResponse;
    if (item.type_read_device !== "ExternalRead") {
      imageUrlResponse = await getUrl(item.id);
    }
    const measurementResponse = await findAllByReadDevice(item.id);
    setImageMeasurements(measurementResponse.data as BasicMeasurement[]);
    const deviceResponse = await getDevice(item.device.id);
    setDevice(deviceResponse.data);

    setImageItem({
      imageUrl: imageUrlResponse?.data ?? "",
      altText: "",
      readDeviceId: item.id,
      imageForm: false,
    });
  };

  const onSaveMeasurement = async (measurements: any[]) => {
    setIsLoading(true);
    try {
      let updateMeasurements: UpdateMeasurementRequestDto[] = [];
      measurements.forEach((measurement) => {
        let updateMeasurement = new UpdateMeasurementRequestDto();
        Object.assign(updateMeasurement, measurement);
        updateMeasurement.measurementId = measurement.id;
        updateMeasurements.push(updateMeasurement);
      });
      const updateResponse = await updateMany(updateMeasurements);
      if (updateResponse.success === true) {
        toast.success(t("UpdateMeasurementSuccess"));
        if (imageItem) {
          const measurementResponse = await findAllByReadDevice(
            imageItem.readDeviceId
          );
          setImageMeasurements(measurementResponse?.data as BasicMeasurement[]);
        }
      }
      await mutate();
    } catch (err) {
      toast.error(`${t("UpdateMeasurementError")}`);
    }
    setIsLoading(false);
  };

  const onDeleteOneMeasurement = async (measurementId: number) => {
    setIsLoading(true);
    try {
      const delResponse = await del(measurementId);

      /**
       * We need to update the measurements list in order
       * to properly display the update measurement list
       * without the recently deleted item.
       */
      if (delResponse.success === true) {
        toast.success(t("DeleteMeasurementSuccess"));
        const toUpdate = imageMeasurements.findIndex(
          (m) => m.id === measurementId
        );
        let newList = [...imageMeasurements];
        newList.splice(toUpdate, 1);
        setImageMeasurements(newList);
        await mutate();
      }
    } catch (err) {
      toast.error(`${t("DeleteMeasurementError")}`);
    }
    setIsLoading(false);
  };

  const onDeleteManyMeasurements = async (readDeviceId: number) => {
    setIsLoading(true);
    try {
      const delResponse = await delByReadDevice(readDeviceId);

      /**
       * We reset measurements list since we deleted all the measurements.
       */
      if (delResponse.success === true) {
        toast.success(t("DeleteManyMeasurementsSuccess"));
        setImageMeasurements([]);
        await mutate();
        setImageItem(null);
        setError(false);
      }
    } catch (err) {
      toast.error(`${t("DeleteManyMeasurementsError")}`);
    }
    setIsLoading(false);
  };

  const onChangeMeasurements = (event: any, signId: number) => {
    const toUpdate = imageMeasurements.findIndex((m) => m.signId === signId);
    let newList = [...imageMeasurements];
    if (toUpdate !== -1) {
      newList[toUpdate].value = +event.target.value;
    } else {
      const newMeasurement = new BasicMeasurement(
        0,
        +event.target.value,
        new Date(),
        signId,
        imageItem!.readDeviceId
      );
      newList.push(newMeasurement);
    }
    setImageMeasurements(newList);
  };

  const showEditImageModal =
    /**
     * If it is an image from a form or the user
     * does not have any permissions,
     * display only the image.
     */
    imageItem &&
    (imageItem.imageForm ||
    (!editMeasurementsPermissions && !deleteMeasurementsPermissions) ? (
      <ImageViewModal
        imageUrl={imageItem.imageUrl}
        altText={imageItem.altText}
        open={Boolean(imageItem)}
        handleClose={() => {
          setImageItem(null);
          setError(false);
        }}
        t={t}
        error={error}
        handleError={handleError}
      />
    ) : (
      <ImageEditModal
        imageUrl={imageItem.imageUrl}
        altText={imageItem.altText}
        open={Boolean(imageItem)}
        handleClose={() => {
          setImageItem(null);
          setError(false);
        }}
        t={t}
        error={error}
        handleError={handleError}
        measurements={imageMeasurements}
        onSave={onSaveMeasurement}
        onDeleteMany={onDeleteManyMeasurements}
        onDeleteOne={onDeleteOneMeasurement}
        editPermissions={editMeasurementsPermissions}
        deletePermissions={deleteMeasurementsPermissions}
        onChange={onChangeMeasurements}
        device={device!}
        readDeviceId={imageItem.readDeviceId}
        measurementToDelete={measurementToDelete}
        setMeasurementToDelete={setMeasurementToDelete}
        readDeviceToDelete={readDeviceToDelete}
        setReadDeviceToDelete={setReadDeviceToDelete}
        currentLanguage={currentLanguage}
      />
    ));
  const lastReadDevicesGridColumns: GridColumns<LastReadDevicesByPatientDataRow> =
    LastReadDevicesByPatientGridColumns({
      timezone,
      showImageModal: openImageModal,
      currentLanguage,
    });

  const lastReadDevicesDataRows: LastReadDevicesByPatientDataRow[] =
    lastReadDevices;

  return (
    <Page title={t("LastMeasurementsTitle")}>
      <FlexLayoutGrid
        language={i18n.language}
        columns={lastReadDevicesGridColumns}
        rows={lastReadDevicesDataRows}
        onPageChange={setPageByLastReadDevices}
        onPageSizeChange={setPageSizeByLastReadDevices}
        pagination={lastReadDevicesByUserPaginationInfo}
        isLoading={isLoading}
        rowHeight={90}
      ></FlexLayoutGrid>
      {showEditImageModal}
    </Page>
  );
};

import { GridColumns, GridValueGetterParams } from "@mui/x-data-grid";
import { useTranslation } from "react-i18next";
import LastReadDevicesByPatientDataRow from "./LastReadDevicesByPatientDataRow";
import { NavLink } from "react-router-dom";
import { sortText } from "../../../../App/sortFunctions";
import { getDeviceName, getMeasurementsInRead, toLocaleString } from "../../utils";
import VisibilityIcon from '@mui/icons-material/Visibility';
import { IconButton, Tooltip } from "@mui/material"
import { BreadcrumbNameMap } from "../../../../breadcrumbMap";
import Launch from "@mui/icons-material/Launch";
import LastReadDevicesDataRow from "../LastReadDevices/LastReadDevicesDataRow";
import { renderCellMeasurement } from "../RenderCellMeasurement";

interface Props {
  timezone: string;
  showImageModal: (item: LastReadDevicesByPatientDataRow) => void;
  currentLanguage: string;
}

export const LastReadDevicesByPatientGridColumns = ({timezone, showImageModal, currentLanguage}: Props): GridColumns<LastReadDevicesByPatientDataRow> => {
  const { t } = useTranslation();

  const gridColumns: any[] = [
    {
      field: 'device.name',
      headerName: t('Device'),
      width: 150,
      valueGetter: (params: GridValueGetterParams) => params.row && params.row.device ? getDeviceName(currentLanguage, params.row.device, t) : '--',
      sortComparator: (v1: string, v2: string) => sortText(v1, v2),
      renderCell: (params: { row: LastReadDevicesByPatientDataRow }) => {
        return (
          <p>{params.row
            && params.row.device ? getDeviceName(currentLanguage, params.row.device, t) : '--'}</p>
        )
      }
    },
    {
      field: 'id',
      headerName: t('Readings'),
      width: 120,
      sortable: false,
      renderCell: (params: { row: LastReadDevicesByPatientDataRow })=> {
        const readDevicePath = BreadcrumbNameMap.ReadDevice.url.replace(":readDeviceId", params.row.id.toString());
        return( 
          <NavLink
            to={readDevicePath}
          >
            <Launch />
          </NavLink>
        );
      }
    },
    {
      field: 'measurements',
      headerName: t('Measurements'),
      width: 150,
      valueGetter: (params: GridValueGetterParams) => params.row ? getMeasurementsInRead(currentLanguage, params.row, t) : '--',
      sortComparator: (v1: string, v2: string) => sortText(v1, v2),
      renderCell: (params: { row: LastReadDevicesDataRow })  => {renderCellMeasurement({params, currentLanguage, t})}
    },
    {
      field: 'image',
      headerName: t('Image'),
      width: 100,
      sortable: false,
      renderCell: (params: { row: LastReadDevicesByPatientDataRow })=> (
        /**
         * If measurement is defined and it's not a Form
         */
        params.row.device 
        && params.row.device.typeName !== 'Form' 
        ?
        (<IconButton onClick={() => showImageModal(params.row)} color="inherit" ><VisibilityIcon /></IconButton>) : '--'
      )
    },
    {
      field: 'created_at',
      headerName: t('CreatedAt'),
      width: 150,
      valueGetter: (params: GridValueGetterParams) => params.row.measurement?.created_at,
      sortComparator: (v1: any, v2: any) => new Date(v2!).getTime() - new Date(v1!).getTime(),
      renderCell: (params: { row: LastReadDevicesByPatientDataRow }) => {
        const content: JSX.Element = (<p>{params.row.created_at ? toLocaleString(new Date(params.row.created_at)) : '--'}</p>);
        return content;
      }
    },
  ];


  return gridColumns;
};

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import {
  CreateImageResponseDto,
  ProposedMeasurement,
} from "@doctomatic/sdk/build/dto/Images";
import { GetDeviceResponseDto } from "@doctomatic/sdk/build/dto/Devices";
import { useTranslation } from "react-i18next";
import { Box, Button, CircularProgress, Modal } from "@mui/material";
import { useApi } from "@doctomatic/sdk/build/Api";
import { processError } from "../../../App/errorToast";
import { CreateMeasurementRequestDto } from "@doctomatic/sdk/build/dto/Measurements/Measurements";
import {
  BasicDeviceDto,
  SignDto,
  SignType,
} from "@doctomatic/sdk/build/dto/Sign/Signs";
import { getSignName } from "../utils";
import { useNavigate } from "react-router-dom";

type Props = {
  createImageResponseDto: CreateImageResponseDto;
  device: GetDeviceResponseDto;
  patientId: number;
  setPhotoTaken: (x: boolean) => void;
  setCreateImageResponseDto: (x: CreateImageResponseDto | null) => void;
  parametersUrl?: string;
  onNextPage?: () => void;
  saveReadDeviceIdToLocalStorage?: (readDeviceId: number) => void;
};

interface IParsedSignDto extends Omit<SignDto, "unit"> {
  parsedUnit: string;
}

class ParsedSignDto implements IParsedSignDto {
  parsedUnit: string = "";
  decimals: number = 0;
  defaultMax?: number | undefined = undefined;
  defaultMin?: number | undefined = undefined;
  max: number = 0;
  min: number = 0;
  name: string = "";
  device: BasicDeviceDto = new BasicDeviceDto();
  typeSign: SignType = SignType.image;
  translations?: string | undefined = undefined;
  id: number = 0;
}

const getUnitName = (sign: SignDto, t: (text: string) => string): string => {
  if (sign.unit === "%SpO2") {
    return t("percentage_SpO2");
  } else if (sign.unit === "") {
    return " ";
  } else {
    return t(sign.unit);
  }
};

const getSignsAndMeasurementsFormatted = (
  deviceName: string,
  signs: SignDto[],
  measurements: ProposedMeasurement[],
  t: (text: string) => string
): {
  signs: ParsedSignDto[];
  measurements: { signId: number; value: number | string }[];
} => {
  if (deviceName !== "Astral150") {
    if (deviceName === "Tensiometer") {
      const index = signs.findIndex((s: SignDto) => s.name === "PP");
      if (index > -1) {
        signs.splice(index, 1);
      }
    }
    let parsedSigns: ParsedSignDto[] = signs.map((sign) => {
      let parsed: ParsedSignDto = new ParsedSignDto();
      Object.assign(parsed, sign);
      parsed.parsedUnit = getUnitName(sign, t);
      return parsed;
    });
    let parsedMeasurements: { signId: number; value: number | string }[] =
      measurements.map((m) => {
        return { signId: m.sign.id, value: m.value };
      });
    return { signs: parsedSigns, measurements: parsedMeasurements };
  } else {
    let filteredSigns = signs.filter((s) => !s.name.startsWith("IE_"));
    const signI = signs.find((s) => s.name === "IE_I");
    const signE = signs.find((s) => s.name === "IE_E");
    let combinedSign = new SignDto();
    Object.assign(combinedSign, signI);
    combinedSign.name = "I_E";
    combinedSign.translations =
      signI!.translations && signE!.translations
        ? signI!.translations.concat(signE!.translations)
        : undefined;

    filteredSigns.push(combinedSign);
    let filteredMeasurements: { signId: number; value: number | string }[] =
      measurements
        .filter((m) => m.sign.id !== signI!.id && m.sign.id !== signE!.id)
        .map((m) => {
          return { signId: m.sign.id, value: m.value };
        });

    const iIndex = measurements.findIndex((m) => m.sign.id === signI!.id);
    const eIndex = measurements.findIndex((m) => m.sign.id === signE!.id);

    const measurementI: string =
      iIndex !== -1 && measurements[iIndex].value
        ? measurements[iIndex].value.toString()
        : "-";
    const measurementE: string =
      eIndex !== -1 && measurements[eIndex].value
        ? measurements[eIndex].value.toString()
        : "-";
    let combinedMeasurement = {
      signId: signI!.id,
      value: measurementI + ":" + measurementE,
    };

    filteredMeasurements.push(combinedMeasurement);

    let parsedSigns: ParsedSignDto[] = filteredSigns.map((sign) => {
      let parsed: ParsedSignDto = new ParsedSignDto();
      Object.assign(parsed, sign);
      parsed.parsedUnit = getUnitName(sign, t);
      return parsed;
    });

    return { signs: parsedSigns, measurements: filteredMeasurements };
  }
};

export const ImageConfirmation = ({
  createImageResponseDto,
  device,
  patientId,
  setPhotoTaken,
  setCreateImageResponseDto,
  parametersUrl,
  onNextPage,
  saveReadDeviceIdToLocalStorage,
}: Props): React.ReactElement => {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const searchParams = new URLSearchParams(location.search);
  const isLogout = searchParams.get("logout") === "true";
  const { logout, useImages, useMeasurements, id: loggedId } = useApi();

  const { confirm, refuse } = useImages(
    false,
    processError(logout, navigate, t)
  );
  const { addMany } = useMeasurements(
    patientId,
    device.id,
    undefined,
    undefined,
    false,
    processError(logout, navigate, t)
  );

  let currentColumn = 1;
  let columnOne: JSX.Element[] = [];
  let columnTwo: JSX.Element[] = [];

  const [open, setOpen] = useState(false);
  const [isCorrect, setIsCorrect] = useState(true);
  const [columnLeft, setColumnLeft] = useState<JSX.Element[]>([]);
  const [columnRight, setColumnRight] = useState<JSX.Element[]>([]);
  const [quantityPerColumn, setQuantityPerColumn] = useState(0);
  const [loading, setLoading] = useState(false);
  const [currentLanguage, setCurrentLanguage] = useState<string>(i18n.language);
  const [measurements, setMeasurements] = useState<
    { signId: number; value: number | string }[]
  >([]);
  const [signs, setSigns] = useState<ParsedSignDto[]>([]);
  const [redirectRefuse, setRedirectRefuse] = useState<string>("");
  const [redirectConfirm, setRedirectConfirm] = useState<string>("");
  const [WHrefuse, setWHrefuse] = useState<string>("");
  const [WHconfirm, setWHconfirm] = useState<string>("");
  const [showModal, setShowModal] = useState<boolean>(false);

  const setImageSuccess = async () => {
    createImageResponseDto &&
      (await confirm(createImageResponseDto.id, patientId));
  };

  const saveMeasurements = async () => {
    let dtos: CreateMeasurementRequestDto[] = [];
    for (const proposedMeasurement of createImageResponseDto?.proposedMeasurements ??
      []) {
      const createMeasurementRequestDto = new CreateMeasurementRequestDto();
      createMeasurementRequestDto.patientId = patientId;
      createMeasurementRequestDto.readDeviceId =
        createImageResponseDto?.id ?? 0;
      createMeasurementRequestDto.signId = proposedMeasurement.sign.id;
      createMeasurementRequestDto.value = proposedMeasurement.value;
      dtos.push(createMeasurementRequestDto);
    }

    if (saveReadDeviceIdToLocalStorage) {
      saveReadDeviceIdToLocalStorage(createImageResponseDto?.id ?? 0);
    }
    await addMany(dtos);
  };

  const handleLogout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("refreshToken");
    localStorage.removeItem("permissions");
    localStorage.removeItem("management");
    logout();
  };

  const redirectUrl = (url: string) => {
    if (redirectConfirm || redirectRefuse) {
      if (!isCorrect) {
        window.location.href = redirectRefuse;
      } else {
        window.location.href = redirectConfirm;
      }
    } else {
      navigate(url);
    }
  };

  const onSave = async () => {
    if (onNextPage) {
      setCreateImageResponseDto(null);
      onNextPage();
    } else {
      if (WHconfirm || WHrefuse) {
        if (!isCorrect && WHrefuse) {
          await webHook(WHrefuse);
        }
        if (isCorrect && WHconfirm) {
          await webHook(WHconfirm);
        }
      }

      if (isLogout) {
        handleLogout();
        redirectUrl(`/devices`);
      } else {
        let path = "/devices";
        let userIdPath = +loggedId !== +patientId ? `/${patientId}` : "";
        if (searchParams) {
          redirectUrl(`${path}${userIdPath}?${searchParams.toString()}`);
        } else {
          redirectUrl(`${path}${userIdPath}`);
        }
      }
      if (open) {
        setCreateImageResponseDto(null);
        setOpen(false);
      }
    }
  };

  const handleCorrectValues = async () => {
    setLoading(true);
    if (showModal) {
      setOpen(true);
    }
    try {
      await setImageSuccess();
      await saveMeasurements();
      localStorage.removeItem("capturedPhoto");
      setPhotoTaken(false);
      if (!showModal) {
        onSave();
      }
    } catch (err: any) {
      localStorage.removeItem("capturedPhoto");
      alert("Server error");
    }
    setLoading(false);
  };

  const handleIncorrectValues = async () => {
    setLoading(true);
    try {
      localStorage.removeItem("capturedPhoto");
      setPhotoTaken(false);
      await refuse(createImageResponseDto?.id ?? 0, patientId);
      if (showModal) {
        setIsCorrect(false);
        setOpen(true);
      }
    } catch (err: any) {
      localStorage.removeItem("capturedPhoto");
      alert("Server error");
    }
    setLoading(false);
  };

  const webHook = async (url: string) => {
    if (url) {
      await fetch(url, {
        method: "GET",
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "*",
          "Access-Control-Allow-Headers": "*",
        },
      });
    }
  };

  useEffect(() => {
    if (isLogout || redirectConfirm || redirectRefuse) {
      setShowModal(true);
    } else {
      setShowModal(false);
    }
  }, [isLogout, redirectConfirm, redirectRefuse]);

  useEffect(() => {
    if (parametersUrl) {
      const param = JSON.parse(parametersUrl);

      if (param.redirectRefuse) {
        setRedirectRefuse(param.redirectRefuse);
      }
      if (param.redirectConfirm) {
        setRedirectConfirm(param.redirectConfirm);
      }
      if (param.WHrefuse) {
        setWHrefuse(param.WHrefuse);
      }
      if (param.WHconfirm) {
        setWHconfirm(param.WHconfirm);
      }
    }
  }, [parametersUrl]);

  useEffect(() => {
    if (device.signs.length > 4) {
      setQuantityPerColumn(Math.ceil(device.signs.length / 2));
    } else if (device.signs.length > 0) {
      setQuantityPerColumn(device.signs.length);
    }
  }, [device.signs]);

  useEffect(() => {
    if (signs.length > 0 && quantityPerColumn > 0) {
      for (let i = 0; i < signs.length; i++) {
        // Resto de tu código para obtener el texto del signo
        const signText = (
          <div
            style={{
              flexDirection: "row",
              alignItems: "flex-end",
              display: "flex",
            }}
          >
            <div
              key={`L${i}-text`}
              style={{
                fontWeight: "bold",
                textAlign: "center",
                marginRight: 3,
                fontSize: quantityPerColumn > 5 ? 13 : 20,
              }}
            >
              {getSignName(currentLanguage, signs[i], t)}:{" "}
            </div>
            <div
              key={`L${i}-sign`}
              style={{
                textAlign: "center",
                fontSize: quantityPerColumn > 5 ? 12 : 21,
              }}
            >
              {measurements.find((pm) => pm.signId === signs[i].id)?.value ??
                "-"}{" "}
              {signs[i].parsedUnit}
            </div>
          </div>
        );

        currentColumn = quantityPerColumn > i ? 1 : 2;

        if (currentColumn === 1) {
          columnOne.push(signText);
        } else {
          columnTwo.push(signText);
        }

        setColumnLeft(columnOne);
        setColumnRight(columnTwo);
      }
    }
  }, [quantityPerColumn]);

  useEffect(() => {
    if (device && createImageResponseDto) {
      const { signs: signsResponse, measurements: measurementsResponse } =
        getSignsAndMeasurementsFormatted(
          device.name,
          device.signs,
          createImageResponseDto.proposedMeasurements ?? [],
          t
        );
      setSigns(signsResponse);
      setMeasurements(measurementsResponse);
    }
  }, [createImageResponseDto]);

  useEffect(() => {
    setCurrentLanguage(i18n.language);
  }, [i18n.language]);

  useEffect(() => {
    if (
      createImageResponseDto.imageToTranslate ||
      !createImageResponseDto.proposedMeasurements ||
      createImageResponseDto.proposedMeasurements.length === 0
    ) {
      setIsCorrect(false);
      setOpen(true);
    }
  }, [createImageResponseDto]);

  const buttons = (
    <div
      style={{
        flexDirection: "row",
        justifyContent: "space-around",
        width: "100%",
        display: "flex",
        marginTop: 10,
      }}
    >
      <Button
        style={{
          borderRadius: 10,
          paddingInline: 10,
          paddingBlock: 20,
          backgroundColor: "#B5B5B5",
          height: 35,
        }}
        onClick={() => {
          setIsCorrect(false);
          handleIncorrectValues();
        }}
      >
        <div
          style={{
            color: "white",
            fontWeight: "bold",
            textAlign: "center",
          }}
        >
          {t("ButtonNo")}
        </div>
      </Button>

      <Button
        style={{
          borderRadius: 10,
          paddingInline: 10,
          paddingBlock: 20,
          backgroundColor: "#4bae51",
          height: 35,
        }}
        onClick={handleCorrectValues}
      >
        <div
          style={{
            color: "white",
            fontWeight: "bold",
            textAlign: "center",
          }}
        >
          {t("ButtonYesSave")}
        </div>
      </Button>
    </div>
  );

  const modal = (
    <Modal
      open={open && showModal}
      onClose={onSave}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <>{!isCorrect && t("ImageSent")}</>
        <>{isCorrect && t("SendAndRedirect")}</>
        <Button
          style={{
            borderRadius: 10,
            paddingInline: 10,
            paddingBlock: 20,
            backgroundColor: "#B5B5B5",
          }}
          onClick={onSave}
        >
          <div
            style={{
              color: "white",
              fontWeight: "bold",
              textAlign: "center",
            }}
          >
            {t("ButtonClose")}
          </div>
        </Button>
      </Box>
    </Modal>
  );

  return (
    <>
      {/* TODO: improve measurements div. Image is currently on top */}
      <div
        style={{
          flexDirection: "column",
          display: "flex",
          margin: 10,
          paddingInline: 10,
          paddingBlock: 10,
          alignItems: "center",
          justifyContent: "flex-start",
          height: "85%",
          overflowY: "auto",
        }}
      >
        {!isCorrect ||
        createImageResponseDto.imageToTranslate ||
        !createImageResponseDto.proposedMeasurements ||
        createImageResponseDto.proposedMeasurements.length === 0 ? (
          !showModal ? (
            <>
              <div
                style={{
                  fontSize: 20,
                  marginInline: 20,
                  textAlign: "center",
                  marginBottom: 15,
                }}
              >
                {t("ImageSent")}
              </div>
              <Button
                style={{
                  borderRadius: 10,
                  paddingInline: 10,
                  paddingBlock: 20,
                  backgroundColor: "#B5B5B5",
                }}
                onClick={onSave}
              >
                <div
                  style={{
                    color: "white",
                    fontWeight: "bold",
                    textAlign: "center",
                  }}
                >
                  {t("ButtonClose")}
                </div>
              </Button>
            </>
          ) : (
            modal
          )
        ) : !showModal || !open ? (
          <>
            <div
              style={{
                fontSize: 20,
                marginBottom: 15,
                textAlign: "center",
              }}
            >
              {t("MeasurementsTitle")}:
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                flexDirection: "column",
              }}
            >
              <div style={{ display: "flex", justifyContent: "center" }}>
                <div
                  style={{
                    flexDirection: "row",
                    width: "100%",
                    justifyContent: "space-around",
                    display: "flex",
                    height: "150px",
                    overflowY: "scroll"
                  }}
                >
                  <div
                    style={{
                      flexDirection: "column",
                      justifyContent: "flex-start",
                      alignItems: "flex-start",
                      display: "flex",
                    }}
                  >
                    {columnLeft}
                  </div>
                  {columnRight.length > 0 && (
                    <div
                      style={{
                        flexDirection: "column",
                        justifyContent: "flex-start",
                        alignItems: "flex-start",
                        display: "flex",
                      }}
                    >
                      {columnRight}
                    </div>
                  )}
                </div>
              </div>
              <div>
                <div
                  style={{
                    marginTop: 15,
                    fontSize: 20,
                    marginInline: 20,
                    textAlign: "center",
                  }}
                >
                  {loading
                    ? t("DetectionSaving")
                    : t("DetectionConfirmationTitle")}
                </div>
                <div
                  style={{
                    flexDirection: "row",
                    justifyContent: "space-around",
                    width: "100%",
                    alignContent: "flex-end",
                    display: "flex",
                  }}
                >
                  {loading ? <CircularProgress size={30} /> : buttons}
                </div>
              </div>
            </div>
          </>
        ) : (
          modal
        )}
      </div>
    </>
  );
};

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  height: 250,
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  outline: "none",
  padding: 3,
  display: "flex",
  alignItem: "center",
  justifyContent: "center",
  alignContent: "space-between",
  flexWrap: "wrap",
};

import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { FlexLayoutGrid } from "@doctomatic/components-react/build/DataGrid/DataGrid";
import { AlertsByValueGridColumns } from "./AlertsByValueGridColumns";
import {
  GetAlertByValueResponseDto
} from "@doctomatic/sdk/build/dto/Alerts/Alerts";
import {
  AlertState
} from "@doctomatic/sdk/build/dto/Alerts/IAlerts";
import AlertsByValueDataRow from "./AlertsByValueDataRow";
import { showAlertStateModal, showAlertSubscribersModal } from "../utils";
import { Role } from "@doctomatic/sdk/build/dto/User";
import { ImageItemInterface } from "../../../Images/utils";
import { ImageViewModal } from "@doctomatic/components-react/build/ImagesViews/ImageViewModal"
import { ResponseApi } from "@doctomatic/sdk/build/global";
import { GridRowClassNameParams, GridSortModel } from "@mui/x-data-grid";
import { DoctomaticStylingRowsGrid } from "../../../Theme/ThemeDataGrid";
import { ImageEditModal } from "@doctomatic/components-react/build/ImagesViews/ImageEditModal";
import { UpdateMeasurementRequestDto, UpdateMeasurementResponseDto, GetMeasurementResponseDto } from "@doctomatic/sdk/build/dto/Measurements/Measurements";
import { PermissionDto } from "@doctomatic/sdk/build/dto/GroupPermission/GroupPermission";
import { BasicMeasurement, Device } from "@doctomatic/components-react/build/Graphs/models";
import { GetDeviceResponseDto } from "@doctomatic/sdk/build/dto/Devices";
import { useLoading } from "../../../Loading/Loading";
import { toast } from "react-toastify";

interface Props {
  alerts: GetAlertByValueResponseDto[] | undefined;
  actorId: number;
  actorRole: Role;
  timezone: string;
  setAlertAck: (id: number, item: any) => void;
  setAlertState: (id: number, item: any) => void;
  setAlertComments: (id: number, comments: string | undefined) => Promise<boolean>;
  showExternalId?: boolean | undefined;
  onPageChange: (newPageNumber: number) => void;
  onPageSizeChange: (newPageSize: number) => void;
  paginationInfo?: any;
  isLoading?: boolean;
  getUrl: (id: number) => Promise<ResponseApi<string>>,
  updateManyMeasurements: (measurement: UpdateMeasurementRequestDto[]) => Promise<ResponseApi<UpdateMeasurementResponseDto[]>>,
  delMeasurement: (measurementId: number) => Promise<ResponseApi<boolean>>,
  delManyMeasurements: (readDeviceId: number) => Promise<ResponseApi<boolean>>,
  findAllByReadDevice: (readDeviceId: number) => Promise<ResponseApi<GetMeasurementResponseDto[]>>,
  getDevice: (id: number) => Promise<ResponseApi<GetDeviceResponseDto>>
  sortBy?: GridSortModel;
  onSortChange?: (newSort: GridSortModel) => void;
}

export const AlertsByValueList = ({ alerts, actorId, actorRole, setAlertAck, setAlertState, setAlertComments, showExternalId, timezone, onPageChange, onPageSizeChange, paginationInfo, isLoading, getUrl, updateManyMeasurements, delMeasurement, delManyMeasurements, findAllByReadDevice, getDevice, sortBy, onSortChange}: Props): React.ReactElement => {
  const { t, i18n } = useTranslation();
  const { setIsLoading } = useLoading()
  const [item, setItem] = useState(null)
  const [currentAlertState, setCurrentAlertState] = useState<{ id: number, state: AlertState } | null>(null)
  const [imageItem, setImageItem] = useState<ImageItemInterface | null>(null)
  const [error, setError] = useState(false);

  const [permissions, setPermissions] = useState<PermissionDto[]>([])
  const [editMeasurementsPermissions, setEditMeasurementsPermissions] = useState<boolean>(false)
  const [deleteMeasurementsPermissions, setDeleteMeasurementsPermissions] = useState<boolean>(false)
  const [imageMeasurements, setImageMeasurements] = useState<BasicMeasurement[]>([]);
  const [device, setDevice] = useState<Device>()
  const [measurementToDelete, setMeasurementToDelete] = useState<{id: number, name: string} | null>(null)
  const [readDeviceToDelete, setReadDeviceToDelete] = useState<boolean>(false)
  const [currentLanguage, setCurrentLanguage] = useState<string>(i18n.language);

  useEffect(() => {
    let cache = localStorage.getItem("permissions");
    if (cache) setPermissions(JSON.parse(cache))
  }, [])

  useEffect(()=> {
    if (permissions && permissions.length > 0){
      if(permissions.find((p) => { return p.key === 'update.measurement' })){
        setEditMeasurementsPermissions(true)
      } else {
      setEditMeasurementsPermissions(false)}
      if(permissions.find((p) => { return p.key === 'delete.measurement' })){
        setDeleteMeasurementsPermissions(true)
      } else {
      setDeleteMeasurementsPermissions(false)}
    }
  },[permissions])

  useEffect(() => {
    setCurrentLanguage(i18n.language);
  }, [i18n.language]);

  const handleError = () => {
    setError(true);
  };

  if (!alerts) return <></>;

  const openAckByModal = (item: any) => {
    setItem(item)
  }

  const openAlertStateModal = (id: number, state: any) => {
    setCurrentAlertState({ id, state })
  }

  const onSaveComments = async (alertId: number, comments: string | undefined): Promise<boolean> => {
    return await setAlertComments(alertId, comments);
  }

  const openImageModal = async (item: AlertsByValueDataRow) => {
    // We force readDevice to be defined since we will only display the modal if both exits
    let imageUrlResponse;
    if (item.measurement.read_device.type_read_device !== "ExternalRead") {
      imageUrlResponse = await getUrl(item.readDeviceId!);
    }

    /**
     * We find the rest of the measurements from the same readDevice.
     */
    const measurementResponse = await findAllByReadDevice(item.readDeviceId!)
    setImageMeasurements(measurementResponse.data as BasicMeasurement[])

    /**
     * We need to get the measurement devie since the device
     * on measurements response doesn't contain all the
     * information requiered.
     */
    const deviceResponse = await getDevice(item.sign.device.id)
    setDevice(deviceResponse.data)

    setImageItem({imageUrl: imageUrlResponse?.data ?? '', altText: item.value, readDeviceId: item.readDeviceId!, imageForm: false})
  }


  const alertsGridColumns = AlertsByValueGridColumns(t, showExternalId, timezone, currentLanguage, openImageModal, openAckByModal, openAlertStateModal, onSaveComments);
  const alertsByValueDataRow: AlertsByValueDataRow[] = alerts.map(
    (alert: GetAlertByValueResponseDto) => {
      const alertByValueDataRow = new AlertsByValueDataRow(
        alert.id,
        alert.patient,
        alert.color,
        alert.label ,
        alert.comments ,
        alert.created_at ,
        alert.ackBy ,
        alert.patient.treatments ? alert.patient.treatments : [],
        actorId,
        actorRole,
        alert.state,
        alert.sign,
        alert.value,
        (item: any) => setAlertAck(alert.id, item),
        alert.valueText !== null ? alert.valueText : undefined,
        alert.measurement.readDeviceId,
        undefined,
        alert.individualConfig,
        alert.templateConfig
      );
      return alertByValueDataRow;
    }
  );

  const onSaveMeasurement = async(measurements: any[]) => {
    setIsLoading(true)
    try {
      let updateMeasurements:UpdateMeasurementRequestDto[] = []
      measurements.forEach(measurement => {
        let updateMeasurement = new UpdateMeasurementRequestDto();
        Object.assign(updateMeasurement, measurement);
        updateMeasurement.measurementId = measurement.id;
        updateMeasurements.push(updateMeasurement);
      })
      const updateResponse = await updateManyMeasurements(updateMeasurements);
      if(updateResponse.success === true) {
        toast.success(t('UpdateMeasurementSuccess'));
      }
    } catch(err) {
      toast.error(`${t('UpdateMeasurementError')}`);
    }
    setIsLoading(false)
  }

  const onDeleteOneMeasurement = async(measurementId: number) => {
    setIsLoading(true)
    try {
      const delResponse = await delMeasurement(measurementId);
      /**
       * We need to update the measurements list in order
       * to properly display the update measurement list
       * without the recently deleted item.
       */
      if(delResponse.success === true) {
        toast.success(t('DeleteMeasurementSuccess'));
        const toUpdate = imageMeasurements.findIndex((m) => m.id === measurementId);
        let newList = [...imageMeasurements];
        newList.splice(toUpdate, 1);
        setImageMeasurements(newList)
      }
    } catch(err) {
      toast.error(`${t('DeleteMeasurementError')}`);
    }
    setIsLoading(false)
  }

  const onDeleteManyMeasurements = async(readDeviceId: number) => {
    setIsLoading(true)
    try {
      const delResponse = await delManyMeasurements(readDeviceId);

      /**
       * We reset measurements list since we deleted all the measurements.
       */
      if(delResponse.success === true) {
        toast.success(t('DeleteManyMeasurementsSuccess'));
        setImageMeasurements([])
      }

    } catch(err) {
      toast.error(`${t('DeleteManyMeasurementsError')}`);
    }
    setIsLoading(false)
  }

  const onChangeMeasurements = (event: any, signId: number) => {
    const toUpdate = imageMeasurements.findIndex((m) => m.signId === signId);
    let newList = [...imageMeasurements];
    if (toUpdate !== -1) {
      newList[toUpdate].value = +event.target.value;
    } else {
      const newMeasurement = new BasicMeasurement(0, +event.target.value, new Date(), signId, imageItem!.readDeviceId);
      newList.push(newMeasurement)
    }
    setImageMeasurements(newList);
  };

  const showModal = (
    item && showAlertSubscribersModal({
      open: Boolean(item),
      item,
      handleClose: () => setItem(null),
      timezone
    })
  )

  const alertStateModal = (
    currentAlertState && showAlertStateModal({
      open: Boolean(currentAlertState),
      currentState: currentAlertState,
      handleClose: (stateSelected: any) => {
        if (typeof stateSelected === 'string' && currentAlertState.state.toString() !== stateSelected.toString().toLowerCase()) {
          setAlertState(currentAlertState.id, stateSelected as AlertState)
        }
        setCurrentAlertState(null)
      },
      t
    })
  )

  const showImageModal = (
    imageItem && ImageViewModal({
      imageUrl: imageItem.imageUrl,
      altText: imageItem.altText,
      open: Boolean(imageItem),
      handleClose: () => {setImageItem(null); setError(false)},
      t: t,
      error: error,
      handleError: handleError
    })
  )

  const showEditImageModal = (
    imageItem && <ImageEditModal
      imageUrl={imageItem.imageUrl}
      altText={imageItem.altText}
      open={imageItem !== null}
      handleClose={() => { setImageItem(null); setError(false); } }
      t={t}
      error={error}
      handleError={handleError}
      measurements={imageMeasurements}
      onSave={onSaveMeasurement}
      onDeleteMany={onDeleteManyMeasurements}
      onDeleteOne={onDeleteOneMeasurement}
      editPermissions={editMeasurementsPermissions}
      deletePermissions={deleteMeasurementsPermissions}
      onChange={onChangeMeasurements}
      device={device!}
      readDeviceId={imageItem.readDeviceId}
      measurementToDelete={measurementToDelete}
      setMeasurementToDelete={setMeasurementToDelete}
      readDeviceToDelete={readDeviceToDelete}
      setReadDeviceToDelete={setReadDeviceToDelete} 
      currentLanguage={currentLanguage}      
    />
  )

  const setRowClassName = (params: GridRowClassNameParams<AlertsByValueDataRow>): string => {
    if (params.row.individualConfig && !params.row.individualConfig.active){
      return 'no-actived-alert-config';
    }
    if (params.row.templateConfig && !params.row.templateConfig.active){
      return 'no-actived-alert-config';
    }

    return ''
  }

  return (<>
    <FlexLayoutGrid
      language={i18n.language}
      columns={alertsGridColumns}
      rows={alertsByValueDataRow}
      onPageChange={onPageChange}
      onPageSizeChange={onPageSizeChange}
      pagination={paginationInfo}
      isLoading = {isLoading}
      setRowClassName={setRowClassName}
      sx={DoctomaticStylingRowsGrid()}
      sortBy={sortBy}
      onSortChange={onSortChange}
    ></FlexLayoutGrid>
    {showModal}
    {alertStateModal}
    {actorRole === Role.user ? showImageModal : showEditImageModal}
  </>
  );
};

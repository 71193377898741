import moment from "moment-timezone";
import React, { useEffect, useMemo, useState } from "react";
import { Page } from "../Page";
import { IProvideApi, useApi } from "@doctomatic/sdk/build/Api";
import { useTranslation } from "react-i18next";
import {
  GetMeasurementResponseDto,
  UpdateMeasurementRequestDto,
  UpdateMeasurementResponseDto,
} from "@doctomatic/sdk/build/dto/Measurements/Measurements";
import {
  Device,
  Measurement,
  Sign,
} from "@doctomatic/components-react/build/Graphs/models";
import { GetDeviceResponseDto } from "@doctomatic/sdk/build/dto/Devices";
import { BreadcrumbProps } from "@doctomatic/components-react/build/BreadcrumbDocto/BreadcrumbDocto";
import { GraphTreatment } from "@doctomatic/components-react/build/Graphs/GraphTreatment/GraphTreatment";
import { DateRangePicker } from "@doctomatic/components-react/build/Graphs/DateRangePicker";
import { useNavigate, useParams } from "react-router-dom";
import { GetTreatmentResponseDto } from "@doctomatic/sdk/build/dto/Treatment/Treatments";
import { SignDto } from "@doctomatic/sdk/build/dto/Sign/Signs";
import { Box } from "@mui/material";
import { BreadcrumbNameMap } from "../../../breadcrumbMap";
import { IUseTreatment } from "@doctomatic/sdk/build/modules/Treatments/Treatment";
import { IUseMeasurementsByTracking } from "@doctomatic/sdk/build/modules/MeasurementsByTreatment";
import { processError } from "../../../App/errorToast";
import convertAndExportCsv from "./export-csv";
import { toast } from "react-toastify";
import { getDeviceName, getSignName, toLocaleString } from "../utils";
import { Role } from "@doctomatic/sdk/build/dto/User";
import { IUseImages } from "@doctomatic/sdk/build/modules/Images";
import { PermissionDto } from "@doctomatic/sdk/build/dto/GroupPermission/GroupPermission";
import { IUseMeasurements } from "@doctomatic/sdk/build/modules/Measurements";
import { useLoading } from "../../Loading/Loading";
import { ResponseApi } from "@doctomatic/sdk/build/global";
import { GetFileResponseDto } from "@doctomatic/sdk/build/dto/Files";
import { formatValues, getValues6Mwt, translates6MWT } from "./utilsTreatments";
interface TreatmentsGraphParams {
  trackingId: string;
  patientId: string;
}

export interface ImageUrls {
  url: string;
  readDeviceId: number;
}

export const TreatmentsGraph = (): React.ReactElement => {
  const navigate = useNavigate();
  const { trackingId, patientId }: TreatmentsGraphParams =
    useParams<TreatmentsGraphParams>();
  const { t, i18n } = useTranslation();
  const {
    useTreatment,
    useMeasurementsByTracking,
    logout,
    role,
    useImages,
    useMeasurements,
    useFiles,
  }: IProvideApi = useApi();
  const { getUrl }: IUseImages = useImages(
    false,
    processError(logout, navigate, t)
  );
  const { response: trackingResponse }: IUseTreatment = useTreatment(
    +trackingId,
    true,
    processError(logout, navigate, t)
  );
  const [dateRange, setDateRange] = useState<[Date | null, Date | null]>([
    null,
    null,
  ]);
  const startDate: string | undefined =
    dateRange[0] === null
      ? undefined
      : moment(dateRange[0]).format("yyyy-MM-DD");
  const endDate: string | undefined =
    dateRange[1] === null
      ? undefined
      : moment(dateRange[1]).format("yyyy-MM-DD");
  const {
    response: measurementsByTrackingResponse,
    mutate,
  }: IUseMeasurementsByTracking = useMeasurementsByTracking(
    +trackingId,
    startDate,
    endDate,
    true,
    processError(logout, navigate, t)
  );
  const {
    updateMany,
    del,
    delByReadDevice,
    findAllByTreatmentExtended,
  }: IUseMeasurements = useMeasurements(
    undefined,
    undefined,
    undefined,
    undefined,
    false,
    processError(logout, navigate, t)
  );
  const { setIsLoading } = useLoading();
  const tracking = trackingResponse?.data;

  // declaration of hooks to handle pagination
  const [pageSizeFiles, setPageSizeFiles] = useState<number>(10);
  const [pageFiles, setPageFiles] = useState<number>(1);

  // Store the pagination value
  // Update pagination when page or pageSize changes
  const paginationValue = useMemo(() => {
    return {
      page: pageFiles,
      limit: pageSizeFiles,
    };
  }, [pageFiles, pageSizeFiles]);

  const { response: filesResponse, getLink } = useFiles(
    +patientId,
    paginationValue,
    startDate,
    endDate,
    true,
    processError(logout, navigate, t)
  );
  const filesPaginationInfo = filesResponse?.meta;
  const files: GetFileResponseDto[] | undefined = filesResponse?.data;
  const getPdf = async (file: GetFileResponseDto) => {
    const { data: link } = await getLink(file.id);
    return link as string;
  };
  const [permissions, setPermissions] = useState<PermissionDto[]>([]);
  const [editMeasurementsPermissions, setEditMeasurementsPermissions] =
    useState<boolean>(false);
  const [deleteMeasurementsPermissions, setDeleteMeasurementsPermissions] =
    useState<boolean>(false);
  const [measurements, setMeasurements] = useState<Measurement[]>([]);
  const [currentLanguage, setCurrentLanguage] = useState<string>(i18n.language);

  useEffect(() => {
    setCurrentLanguage(i18n.language);
  }, [i18n.language]);

  useEffect(() => {
    let cache = localStorage.getItem("permissions");
    if (cache) setPermissions(JSON.parse(cache));
  }, []);

  useEffect(() => {
    if (tracking) {
      const updatedMeasurements =
        measurementsByTrackingResponse?.data.map(
          ({
            id,
            value,
            createdAt,
            signId,
            readDeviceId,
            valueText,
            sign,
            readDevice,
          }: GetMeasurementResponseDto) =>
            new Measurement(
              id,
              value,
              createdAt,
              signId,
              tracking?.patientId,
              readDeviceId,
              valueText,
              sign,
              sign.device.typeName,
              readDevice
            )
        ) ?? [];
      setMeasurements(updatedMeasurements);
    }
  }, [measurementsByTrackingResponse, tracking]);

  useEffect(() => {
    if (permissions && permissions.length > 0) {
      if (
        permissions.find((p) => {
          return p.key === "update.measurement";
        })
      ) {
        setEditMeasurementsPermissions(true);
      } else {
        setEditMeasurementsPermissions(false);
      }
      if (
        permissions.find((p) => {
          return p.key === "delete.measurement";
        })
      ) {
        setDeleteMeasurementsPermissions(true);
      } else {
        setDeleteMeasurementsPermissions(false);
      }
    }
  }, [permissions]);

  if (!tracking) return <></>;

  const devices: Device[] = tracking
    ? tracking.devices.map((treatmentDevice: GetDeviceResponseDto) => {
        const signs: Sign[] = treatmentDevice.signs.map((s: SignDto) => {
          s.name = getSignName(currentLanguage, s, t);
          return Object.assign({} as Sign, s);
        });
        return {
          id: treatmentDevice.id,
          name: getDeviceName(currentLanguage, treatmentDevice, t),
          signs: signs,
          formDevice: treatmentDevice.formDevice,
        } as Device;
      })
    : [];

  const onSaveMeasurement = async (measurements: any[]) => {
    let updateResponse: undefined | ResponseApi<UpdateMeasurementResponseDto[]>;
    setIsLoading(true);
    try {
      let updateMeasurements: UpdateMeasurementRequestDto[] = [];
      measurements.forEach((measurement) => {
        let updateMeasurement = new UpdateMeasurementRequestDto();
        Object.assign(updateMeasurement, measurement);
        updateMeasurement.measurementId = measurement.id;
        updateMeasurements.push(updateMeasurement);
      });
      updateResponse = await updateMany(updateMeasurements);
      if (updateResponse.success === true) {
        toast.success(t("UpdateMeasurementSuccess"));
        await mutate();
      }
    } catch (err) {
      toast.error(`${t("UpdateMeasurementError")}`);
    }
    setIsLoading(false);
    return updateResponse;
  };

  const onDeleteOneMeasurement = async (measurementId: number) => {
    setIsLoading(true);
    try {
      const delResponse = await del(measurementId);
      if (delResponse.success === true) {
        toast.success(t("DeleteMeasurementSuccess"));
        await mutate();
      }
    } catch (err) {
      toast.error(`${t("DeleteMeasurementError")}`);
    }
    setIsLoading(false);
  };

  const onDeleteManyMeasurements = async (readDeviceId: number) => {
    setIsLoading(true);
    try {
      const delResponse = await delByReadDevice(readDeviceId);
      if (delResponse.success === true) {
        toast.success(t("DeleteManyMeasurementsSuccess"));
        await mutate();
      }
    } catch (err) {
      toast.error(`${t("DeleteManyMeasurementsError")}`);
    }
    setIsLoading(false);
  };

  const getBreadCrumbProps = (
    treatment: GetTreatmentResponseDto | undefined
  ): BreadcrumbProps =>
    ({
      breadcrumbItems:
        role === Role.user
          ? [
              {
                url: BreadcrumbNameMap.Trackings.url,
                name: t("TrackingsTitle"),
              },
              { url: "", name: treatment?.name ?? "" },
            ]
          : [
              { url: BreadcrumbNameMap.Patients.url, name: t("Patients") },
              {
                url: `/patients/${treatment?.patientId}`,
                name: treatment?.patient?.name ?? "",
              },
              {
                url: BreadcrumbNameMap.Trackings.url,
                name: t("TrackingsTitle"),
              },
              { url: "", name: treatment?.name ?? "" },
            ],
    } as BreadcrumbProps);

  const filters = (
    <Box display="flex" justifyContent="flex-end" height={"100%"}>
      <DateRangePicker
        dateRangePicked={dateRange}
        setDateRange={setDateRange}
        t={t}
      />
    </Box>
  );

  const getUrlsImage = async (readDeviceId: number, imageUrls: ImageUrls[]) => {
    const existReadDeviceId = imageUrls.find(
      (aux) => aux.readDeviceId === readDeviceId
    );
    if (!existReadDeviceId) {
      const response = await getUrl(readDeviceId);

      let url;
      if (response.success) {
        url = response?.data;
      }
      return { url: url as string, readDeviceId: readDeviceId };
    }
    return {} as ImageUrls;
  };

  const onExport = async (
    measurements: Measurement[],
    signs: Sign[],
    device: Device
  ): Promise<void> => {
    // TODO: update formatedMeasurements type to use strings
    const formattedMeasurements: Measurement[] = [];
    const imageUrls: ImageUrls[] = [];

    if (device.name === "ReadSixMWT") {
      // Get all the measurements of the 6MWT
      const measurementExtendedBy6MWT = await findAllByTreatmentExtended(
        +trackingId
      );

      if (measurementExtendedBy6MWT && measurementExtendedBy6MWT.data) {
        for (const element of measurementExtendedBy6MWT?.data) {
          for (const m of element.measurements) {
            if (m.steps) {
              for (const translation of translates6MWT) {
                imageUrls.push(
                  await getUrlsImage(m[translation[0]].id, imageUrls)
                );
              }
            }
          }
        }

        for (const element of measurementExtendedBy6MWT?.data) {
          for (const m of element.measurements) {
            if (m.steps) {
              formattedMeasurements.push(
                ...getValues6Mwt(element, m, t, imageUrls, true)
              );
            }
          }
        }
      }
    } else {
      const signIds: number[] =
        device?.signs.map((sign: Sign) => sign.id) ?? [];
      const filteredMeasurements: Measurement[] = measurements.filter(
        (value: Measurement) => signIds.includes(value.signId)
      );
      await Promise.all(
        filteredMeasurements.map(async (measurement) => {
          imageUrls.push(
            await getUrlsImage(measurement.readDeviceId, imageUrls)
          );
        })
      );
      filteredMeasurements.forEach(async (m: Measurement) => {
        const sign: Sign | undefined = signs.find(
          (value: Sign) => value.id === m.signId
        );
        formattedMeasurements.push({
          id: m.id,
          sign: sign ? formatValues(t(sign.name)) : undefined,
          value: +formatValues(m.value ? m.value.toString() : ""),
          unit: sign ? formatValues(sign.unit) : undefined,
          image: imageUrls.find(
            (imageUrl) => imageUrl.readDeviceId === m.readDeviceId
          )?.url,
          device: formatValues(t(device.name)),
          createdAt: formatValues(toLocaleString(new Date(m.createdAt))),
        });
      });
    }

    try {
      let headers = {
        id: "Id",
        sign: formatValues(t("Sign")),
        value: formatValues(t("Value")),
        unit: formatValues(t("Unit")),
        image: formatValues(t("Image")),
        device: formatValues(t("Device")),
        createdAt: formatValues(t("CreatedAt")),
      };
      let fileName = `tracking_${+trackingId}-patient_${tracking?.patientId}`;
      convertAndExportCsv(headers, formattedMeasurements, fileName);
    } catch (err: any) {
      toast.error(
        `${t("ErrorExportTreatmentMeasurements")}: ${
          err.response?.data?.message || err.message
        }`
      );
    }
  };

  return (
    <Page
      title=""
      buttons={filters}
      breadCrumbProps={getBreadCrumbProps(tracking)}
    >
      <GraphTreatment
        t={t}
        language={currentLanguage}
        measurements={measurements}
        devices={devices}
        start={dateRange[0] ? moment(dateRange[0]).toDate() : undefined}
        end={dateRange[1] ? moment(dateRange[1]).toDate() : undefined}
        onExport={onExport}
        getUrl={getUrl}
        onSaveMeasurement={onSaveMeasurement}
        editMeasurementsPermissions={editMeasurementsPermissions}
        deleteMeasurementsPermissions={deleteMeasurementsPermissions}
        onDeleteOneMeasurement={onDeleteOneMeasurement}
        onDeleteManyMeasurements={onDeleteManyMeasurements}
        files={files}
        getPdf={getPdf}
        pagination={filesPaginationInfo}
        onPageChangeFiles={setPageFiles}
        onPageSizeChangeFiles={setPageSizeFiles}
      />
    </Page>
  );
};

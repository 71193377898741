import { FlexLayoutGrid } from "@doctomatic/components-react/build/DataGrid/DataGrid";
import { IProvideApi, useApi } from "@doctomatic/sdk/build/Api";
import { GridColumns } from "@mui/x-data-grid";
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Page } from "../../../Page";
import { LastAlertsByValueGridColumns } from "./LastAlertsByValueByPatientGridColumns";
import { processError } from "../../../../../App/errorToast";
import { IUseLastAlertsByValueByPatient } from "@doctomatic/sdk/build/modules/LastAlertsByValueByPatient";
import LastAlertsByValueByPatientDataRow from "./LastAlertsByValueByPatientDataRow";
import {
  showAlertStateModal,
  showAlertSubscribersModal,
} from "../../../Alerts/utils";
import { Role } from "@doctomatic/sdk/build/dto/User";
import { AlertState } from "@doctomatic/sdk/build/dto/Alerts/IAlerts";
import { useLoading } from "../../../../Loading/Loading";
import { SignType } from "@doctomatic/sdk/build/dto/Sign/Signs";
import { ImageItemInterface } from "../../../../Images/utils";
import { ImageViewModal } from "@doctomatic/components-react/build/ImagesViews/ImageViewModal";
import { ItemAlertByValueList } from "@doctomatic/sdk/build/dto/Alerts/Alerts";
import { useNavigate } from "react-router-dom";

export interface ILastAlertsByValueByPatientListProps {
  actorId: number;
  patientId: number;
  actorRole: Role;
}

export const LastAlertsByValueByPatientList = (
  props: ILastAlertsByValueByPatientListProps
): React.ReactElement => {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const {
    useLastAlertsByValueByPatient,
    useAlerts,
    logout,
    timezone,
    useImages,
  }: IProvideApi = useApi();
  // const { response, mutate: mutateAlertsByValue }: IUseLastAlertsByValueByPatient = useLastAlertsByValueByPatient(props.actorId, props.actorRole, props.patientId, true, processError(logout, navigate, t));

  // const lastAlerts = response?.data;
  const [item, setItem] = useState(null);
  const [currentAlertState, setCurrentAlertState] = useState<{
    id: number;
    state: AlertState;
  } | null>(null);
  const [lastAlerts, setLastAlerts] = useState<ItemAlertByValueList[]>([]);
  const { setIsLoading, isLoading } = useLoading();
  const { updateAlertState, updateAlertComments } = useAlerts();
  const { getUrl } = useImages(false, processError(logout, navigate, t));
  const [imageItem, setImageItem] = useState<ImageItemInterface | null>(null);
  const [error, setError] = useState(false);

  // Declaration of hooks to handle pagination
  const [pageSizeByValue, setPageSizeByValue] = useState<number>(10);
  const [pageByValue, setPageByValue] = useState<number>(1);

  // Store the pagination value
  // Update pagination when page or pageSize changes
  const paginationValue = useMemo(() => {
    return {
      page: pageByValue,
      limit: pageSizeByValue,
    };
  }, [pageByValue, pageSizeByValue]);
  const {
    response,
    mutate: mutateAlertsByValue,
  }: IUseLastAlertsByValueByPatient = useLastAlertsByValueByPatient(
    props.actorId,
    props.actorRole,
    props.patientId,
    true,
    paginationValue,
    processError(logout, navigate, t)
  );

  const alertsByValue = response?.data;
  const alertsByValuePaginationInfo = response?.meta;

  const setAlertState = (id: number, state: AlertState) => {
    const setAlertStatusAsync = async (id: number) => {
      setIsLoading(true);
      try {
        await updateAlertState(id, { state });
        mutateAlertsByValue();
      } catch (err) {
        setIsLoading(false);
        throw err;
      }
      setIsLoading(false);
    };
    setAlertStatusAsync(id);
  };

  const setAlertComments = (id: number, comments: string | undefined) => {
    const setAlertCommentsAsync = async (
      id: number,
      comments: string | undefined
    ) => {
      setIsLoading(true);
      const response = await updateAlertComments(id, { comments });
      mutateAlertsByValue();
      setIsLoading(false);
      return response.success;
    };
    return setAlertCommentsAsync(id, comments);
  };

  useEffect(() => {
    if (alertsByValue) setLastAlerts(alertsByValue);
  }, [alertsByValue, alertsByValuePaginationInfo]);

  const openAckByModal = (item: any) => {
    setItem(item);
  };
  const openAlertStateModal = (id: number, state: any) => {
    setCurrentAlertState({ id, state });
  };

  const openImageModal = async (item: any) => {
    let imageUrlResponse: any;
    if (item.sign.typeSign === SignType.image) {
      imageUrlResponse = await getUrl(
        item.measurement!.readDeviceId,
        item.measurement!.id
      );
      setImageItem({
        imageUrl: imageUrlResponse?.data ?? "",
        altText: "",
        readDeviceId: item.measurement!.readDeviceId,
        imageForm: true,
      });
    } else {
      if (item.measurement.readDevice.typeReadDevice !== "ExternalRead") {
        imageUrlResponse = await getUrl(item.measurement!.readDeviceId);
      }
      setImageItem({
        imageUrl: imageUrlResponse?.data ?? "",
        altText: item.measurement!.value,
        readDeviceId: item.measurement!.readDeviceId,
        imageForm: false,
      });
    }
  };

  const lastAlertsGridColumns: GridColumns<LastAlertsByValueByPatientDataRow> =
    LastAlertsByValueGridColumns(
      timezone,
      openAckByModal,
      openAlertStateModal,
      setAlertComments,
      openImageModal
    );

  const lastAlertsDataRows: LastAlertsByValueByPatientDataRow[] = lastAlerts
    ? lastAlerts
    : [];

  const showModal =
    item &&
    showAlertSubscribersModal({
      open: Boolean(item),
      item,
      handleClose: () => setItem(null),
      timezone: timezone,
    });

  const alertStateModal =
    currentAlertState &&
    showAlertStateModal({
      open: Boolean(currentAlertState),
      currentState: currentAlertState,
      handleClose: (stateSelected: any) => {
        if (
          typeof stateSelected === "string" &&
          currentAlertState.state.toString() !==
            stateSelected.toString().toLowerCase()
        ) {
          setAlertState(currentAlertState.id, stateSelected as AlertState);
        }
        setCurrentAlertState(null);
      },
      t,
    });

  const handleError = () => {
    setError(true);
  };

  const showEditImageModal = imageItem && (
    <ImageViewModal
      imageUrl={imageItem.imageUrl}
      altText={imageItem.altText}
      open={Boolean(imageItem)}
      handleClose={() => {
        setImageItem(null);
        setError(false);
      }}
      t={t}
      error={error}
      handleError={handleError}
    />
  );

  return (
    <Page title={t("LastAlertsByValueTitle")}>
      <FlexLayoutGrid
        language={i18n.language}
        columns={lastAlertsGridColumns}
        rows={lastAlertsDataRows}
        onPageChange={setPageByValue}
        onPageSizeChange={setPageSizeByValue}
        pagination={alertsByValuePaginationInfo}
        isLoading={isLoading}
      ></FlexLayoutGrid>
      {showModal}
      {alertStateModal}
      {showEditImageModal}
    </Page>
  );
};
